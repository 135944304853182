/*------------------------------------*\
    # component.scroollbar
\*------------------------------------*/

::-webkit-scrollbar {
    width: calc($unit * 2);
    height: calc($unit * 2);
    border-radius: $radius-full;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

::-webkit-scrollbar-thumb {
    border-radius: $radius-base;
    background-color: $color-primary;
}