/*------------------------------------*\
    # component.stations
\*------------------------------------*/

.c-stations {
    &__card {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;

        padding: calc($unit * 4);

        border-radius: $radius-base;
        background-color: $color-background-primary;
    }

    &__header {
        display: flex;
        align-items: center;

        padding: calc($unit * 4);

        border-radius: $radius-base;
        background-color: $elevation-06;

        &__img {
            width: 181px;
            height: 181px;

            border-radius: $radius-full;
        }
    }

    &__preview {
        display: flex;
        flex-direction: column;
        gap: calc($unit * 4);

        @media (--screen-from-med) {
            gap: calc($unit * 12);
            flex-direction: row;
        }

        &__img {
            @media (--screen-to-med) {
                margin: 0 auto;
            }
        }
    }

    &__desc {
        flex: 1;
    }

    &__upload {
        display: flex;

        &__img {
            width: 355px;
            height: 360px;

            margin-bottom: calc($unit * 7);

            & img {
                width: 100%;
                height: 100%;
            }
        }

        &__footer {
            width: 100%;
            display: flex;
            justify-content: space-between;
        }
    }

    &__payment {
        width: 100%;

        @media (--screen-from-xxlrg) {
            width: 50%;
        }

        @media (--screen-from-xlrg) {
            width: 80%;
        }

        @media (--screen-from-lrg) {
            width: 90%;
        }
    }
}
