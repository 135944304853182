/*------------------------------------*\
    # component.shuffle
\*------------------------------------*/

.c-shuffle {
    &__search {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        &__input {
            min-width: 220px;
            width: 100%;
            margin-bottom: calc($unit * 3);

            @media (--screen-from-med) {
                flex: 1;
                margin-right: calc($unit * 3);
                margin-bottom: 0;
            }

            &--secondary {
                max-width: 290px;
                margin-top: calc($unit * 3);
                margin-right: 0;

                @media (--screen-from-med) {
                    margin-top: 0;
                }
            }
        }

        & .c-btn {
            height: 38px;
        }
    }

    &__item {
        padding: calc($unit * 3) calc($unit * 3);
        margin-bottom: calc($unit * 4);

        background-color: $elevation-00;
        border-radius: $radius-base;

        font-size: responsive $type-sml $type-base;
        font-range: 320px 1440px;

        line-height: responsive 20px 24px;
        line-height-range: 320px 1440px;

        &:last-child {
            margin-bottom: 0;
        }

        &__header {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }

    &__action {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        &__item {
            padding: $unit 0;

            @media (--screen-from-med) {
                padding: 0;
            }
        }
    }

    &__status {
        display: inline-flex;
        align-items: center;

        padding: calc($unit / 2) calc($unit * 3);

        border-radius: calc($unit * 3);

        font-size: $type-sml;
        line-height: 20px;

        &--active {
            /* border: 1px solid $color-success; */
            color: $color-success;
        }

        &--inactive {
            /* border: 1px solid color($color-text-light a(0.6)); */
            color: color($color-text-light a(0.6));
        }
    }
}
