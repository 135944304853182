/*------------------------------------*\
    # component.c-content
\*------------------------------------*/

.c-avatar {
    display: flex;
    align-items: center;

    padding: calc($unit * 3) calc($unit * 6);

    font-size: responsive $type-sml $type-base;
    font-range: 320px 1440px;

    line-height: responsive 20px 24px;
    line-height-range: 320px 1440px;

    &__image {
        width: 24px;
        height: 24px;
        border-radius: $radius-full;
        margin-right: calc($unit * 1.5);
    }

    &__title {
        width: calc($unit * 30);

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: inline-block;
    }
}
