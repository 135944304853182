/*------------------------------------*\
    # component.chart
\*------------------------------------*/

.c-chart {
    max-width: 270px;

    &--sml {
        max-width: 180px;
    }

    &--label {
        font-size: 0.23em;
        text-anchor: middle;
        -moz-transform: translateY(-0.2em);
        -ms-transform: translateY(-0.2em);
        -webkit-transform: translateY(-0.2em);
        transform: translateY(-0.2em);

        @media (--screen-from-lrg) {
            font-size: 0.13em;
            -moz-transform: translateY(-0.6em);
            -ms-transform: translateY(-0.6em);
            -webkit-transform: translateY(-0.6em);
            transform: translateY(-0.6em);
        }
    }

    &--number {
        font-size: 0.3em;
        line-height: 1;
        text-anchor: middle;
        -moz-transform: translateY(-1.05em);
        -ms-transform: translateY(-1.05em);
        -webkit-transform: translateY(-1.05em);
        transform: translateY(-1.05em);

        @media (--screen-from-lrg) {
            font-size: 0.2em;
            -moz-transform: translateY(-1.35em);
            -ms-transform: translateY(-1.35em);
            -webkit-transform: translateY(-1.35em);
            transform: translateY(-1.35em);
        }
    }

    &--text {
        fill: $color-text-light;
        -moz-transform: translateY(0.25em);
        -ms-transform: translateY(0.25em);
        -webkit-transform: translateY(0.25em);
        transform: translateY(0.25em);
    }

    &--doughnut {
        & canvas {
            height: 165px !important;
        }
    }

    &__line {
        &--med {
            & canvas {
                height: 360px !important;
                width: 100% !important;
            }
        }
    }
}
