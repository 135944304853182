/*------------------------------------*\
    # utils.display
\*------------------------------------*/

.u {
    &-display {
        &--i {
            display: inline;
        }

        &--b {
            display: block;
        }

        &--ib {
            display: inline-block;
        }

        &--if {
            display: inline-flex;
        }

        &--none {
            display: none;
        }

        &--grid {
            display: grid;
        }

        &--flex {
            display: flex;

            &--1 {
                flex: 1;
            }

            &--column {
                flex-direction: column;
            }

            &--jc {
                &--center {
                    justify-content: center;
                }

                &--space-between {
                    justify-content: space-between;
                }

                &--flex-start {
                    justify-content: flex-start;
                }

                &--flex-end {
                    justify-content: flex-end;
                }
            }

            &--ai {
                &--center {
                    align-items: center;
                }

                &--flex-start {
                    align-items: flex-start;
                }

                &--flex-end {
                    align-items: flex-end;
                }

                &--baseline {
                    align-items: baseline;
                }
            }

            &--wrap {
                flex-wrap: wrap;
            }
        }
    }
}
