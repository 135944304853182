/*------------------------------------*\
    # component.c-content-policy
\*------------------------------------*/

.c-content-policy {
    &__card {
        /* position: absolute;

        bottom: calc($unit * 10);
        left: calc($unit * 6);
        right: calc($unit * 6); */

        display: flex;
        flex-direction: column;
        align-items: flex-start;

        padding: calc($unit * 3);
        margin: calc($unit * 3) 15px;

        background-color: $elevation-00;
        border-radius: $radius-base;
        border: 1px solid $elevation-10;
    }
}
