/*------------------------------------*\
    # component.container
\*------------------------------------*/

.c-container {
    padding: calc($unit * 6);
    padding-top: calc($unit * 8);

    &--sml {
        max-width: 425px;

        &--alt {
            max-width: 590px;
        }
    }

    &--base {
        max-width: 768px;
    }

    &--med {
        max-width: 1024px;

        &--alt {
            max-width: 1280px;
        }
    }

    &--lrg {
        max-width: 1440px;
    }

    &--full {
        max-width: 100%;
    }

    &--content {
        width: 100%;
        padding: calc($unit * 4);
        padding-top: calc($unit * 8);

        @media (--screen-from-lrg) {
            padding: calc($unit * 6);
        }

        &--sml {
            display: grid;
            grid-template-columns: 1fr;
            grid-gap: calc($unit * 6);

            @media (--screen-from-med) {
                grid-template-columns: 370px 340px;
            }

            @media (--screen-from-lrg) {
                grid-template-columns: 450px 300px;
            }

            @media (--screen-from-xlrg) {
                grid-template-columns: 600px 380px;
            }
        }

        &--main {
            display: grid;
            grid-template-columns: 1fr;
            grid-gap: calc($unit * 6);

            max-height: 100%;

            padding: calc($unit * 4);
            padding-top: calc($unit * 8);

            @media (--screen-from-xxlrg) {
                grid-template-columns: 1fr 387px;
            }

            @media (--screen-from-lrg) {
                padding: calc($unit * 6);
            }
        }
    }

    &--sidebar {
        display: grid;
        grid-template-columns: 100%;
        grid-gap: calc($unit * 6);

        max-height: 100%;

        padding: calc($unit * 4);
        padding-top: calc($unit * 8);

        @media (--screen-from-xlrg) {
            grid-template-columns: 1fr 387px;
        }

        @media (--screen-from-lrg) {
            padding: calc($unit * 6);
        }

        & .order-1 {
            order: 2;

            @media (--screen-from-xlrg) {
                order: 1;
            }
        }

        & .order-2 {
            order: 1;

            @media (--screen-from-xlrg) {
                order: 2;
            }
        }

        &--xxlrg {
            grid-template-columns: 1fr;

            @media (--screen-from-xxlrg) {
                grid-template-columns: 1fr 387px;
            }
        }

        &--secondary {
            display: grid;
            grid-template-columns: 1fr;

            @media (--screen-from-lrg) {
                grid-template-columns: 1fr 1fr;
            }

            @media (--screen-from-xxlrg) {
                grid-template-columns: 1fr 1fr 1fr;
            }
        }

        &--med {
            grid-template-columns: 1fr;

            @media (--screen-from-lrg) {
                grid-template-columns: 1fr 50%;
            }

            @media (--screen-from-xlrg) {
                grid-template-columns: 1fr minmax(380px, 500px);
            }

            & .order-1 {
                order: 2;

                @media (--screen-from-lrg) {
                    order: 1;
                }
            }

            & .order-2 {
                order: 1;

                @media (--screen-from-lrg) {
                    order: 2;
                }
            }
        }

        &--lrg {
            grid-template-columns: 1fr;

            @media (--screen-from-med) {
                grid-template-columns: 1fr 1fr;
            }
        }
    }
}
