/*------------------------------------*\
    # modules.c-toggle
\*------------------------------------*/

.c-toggle {
    opacity: 0;
    position: absolute;

    &--secondary {
        display: flex;
        gap: calc($unit * 2);
        min-width: 150px;
    }

    &__card-list {
        display: flex;
        flex-direction: column;
        gap: calc($unit * 3);

        padding: calc($unit * 3);

        background-color: $elevetion-01;
        border-radius: $radius-base;
    }

    &__item {
        width: 50%;

        margin-bottom: calc($unit * 3);

        @media (--screen-from-sml) {
            width: auto;
            margin-right: calc($unit * 7);
        }

        &--inline {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }

    &:focus {
        & + .toggle-button {
            outline: 0;
            border-radius: $radius-full;
            box-shadow: 0px 0px 0px 2px $color-secondary inset;
        }
    }

    &,
    &:after,
    &:before,
    & *,
    & *:after,
    & *:before,
    & + .toggle-button {
        box-sizing: border-box;
        &::selection {
            background: none;
        }
    }

    + .toggle-button {
        position: relative;

        outline: 0;
        display: block;

        width: calc($unit * 10);
        height: calc($unit * 6);
        padding: 2px;

        cursor: pointer;
        user-select: none;

        background-position: 80% center;
        background-size: calc($unit * 2);
        background-repeat: no-repeat;

        border-radius: $radius-full;
        transition: all 250ms cubic-bezier(0.68, -0.55, 0.265, 1.55);

        /* Theming */
        &--regular {
            background-color: transparent;
            border: 1px solid $color-text-light;
        }

        &--theme {
            background-color: #cde7ff;
        }

        &:after,
        &:before {
            position: relative;
            display: block;
            content: "";
            width: 50%;
            height: 100%;
        }

        &:after {
            left: 0;
            border-radius: 50%;
            background-color: #fff;
            transition: all 250ms ease-out;
            box-shadow: 1px 2px 2px 0 rgba(0, 0, 0, 0.34);
        }
        &:hover:after {
            box-shadow: 2px 3px 3px 0 rgba(0, 0, 0, 0.34);
        }

        &:before {
            display: none;
        }
    }

    &:checked + .toggle-button {
        background-position: 20% center;
        background-size: calc($unit * 2);
        background-repeat: no-repeat;

        &--regular {
            border: 1px solid $color-primary;
            background-color: $color-primary;
        }

        &--theme {
            background-color: #28507f;
        }

        &:after {
            left: 50%;
        }
    }

    &[disabled] + .toggle-button {
        cursor: auto;

        &:after {
            background-color: $elevation-06;
            box-shadow: 1px 2px 2px 0 rgba(0, 0, 0, 0.14);
        }

        &--regular {
            border: 1px solid $color-border;
            background-color: $color-border;
        }
    }
}
