/*------------------------------------*\
    # utils.visibility
\*------------------------------------*/
.u {
	&-visibility {
		&--hidden {
			visibility: hidden;
		}

		&--visible {
			visibility: visible;
		}
	}
}
