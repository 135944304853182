/*------------------------------------*\
    # utils.height
\*------------------------------------*/

.u-height {
    &--25 {
        height: 25%;

        &--px {
            height: 25px;
        }
    }

    &--50 {
        height: 50%;

        &--px {
            height: 50px;
        }
    }

    &--75 {
        height: 75%;

        &--px {
            height: 75px;
        }
    }

    &--80 {
        height: 80%;

        &--px {
            height: 80px;
        }
    }

    &--100 {
        height: 100%;

        &--px {
            height: 100px;
        }

        &--from {
            &-med {
                @media (--screen-from-med) {
                    height: 100%;
                }
            }
        }

        &--to {
            &-lrg {
                @media (--screen-to-lrg) {
                    height: 100%;
                }
            }
        }
    }

    &--140 {
        &--max {
            max-height: 140px;
        }
    }

    &--350 {
        &--max {
            max-height: 350px;
        }
    }

    &--530 {
        &--max {
            max-height: 530px;
        }
    }

    &--660 {
        height: 660px;

        &--max {
            max-height: 660px;
        }
    }
}
