/*------------------------------------*\
    # generic.boxsizing
\*------------------------------------*/

*,
*:before,
*:after {
    box-sizing: inherit;
}

html {
    box-sizing: border-box;
}