.u-animation {
    &--fadeIn {
        /* width: 100%; */
        /* display: inline; */

        animation-name: fadeIn;
        animation-duration: 250ms;


        @keyframes fadeIn {
            0% {
                opacity: 0;
            }
            50% {
                opacity: 0.5;
            }
            100% {
                opacity: 1;
            }
        }
    }
}
