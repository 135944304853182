.c-grid {
    display: grid;

    &__gap {
        &--2 {
            gap: calc($unit * 2);
        }

        &--6 {
            gap: calc($unit * 6);
        }
    }

    &--3 {
        grid-template-columns: repeat(3, 1fr);
    }
}
