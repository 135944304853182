/*------------------------------------*\
    # component.c-content
\*------------------------------------*/
.c-content {
    &__header {
        &--primary {
            display: flex;
            flex-direction: column;

            padding: calc($unit * 6);

            background-color: $elevation-06;

            @media (--screen-from-med) {
                align-items: center;
                flex-direction: row;
            }

            & img {
                width: 130px;
                min-width: 130px;
                height: 130px;

                margin-bottom: calc($unit * 4);

                border-radius: $radius-full;
                background-color: $elevation-00;

                object-fit: cover;

                @media (--screen-from-med) {
                    margin-bottom: 0;
                }
            }

            &__info {
                margin-left: 0;

                @media (--screen-from-sml) {
                    margin-left: calc($unit * 6);
                }
            }

            &__descriprtion {
                max-width: 600px;

                font-size: responsive $type-sml $type-base;
                font-range: 320px 1440px;

                line-height: responsive 20px 24px;
                line-height-range: 320px 1440px;
            }

            &__title {
                color: $color-text-light;
                font-weight: $type-weight-bold;

                font-size: responsive $type-lrg $type-xlrg;
                font-range: 320px 1440px;

                line-height: responsive 40px 44px;
                line-height-range: 320px 1440px;
            }
        }

        &--secondary {
            margin-bottom: calc($unit * 4);
        }

        &--no-image {
            flex-direction: row;
        }

        &__image-wrapper {
            position: relative;
            overflow: hidden;

            border-radius: $radius-full;
            border: 1px solid color($color-text-light a(0.25));
        }
    }

    &__main {
        max-height: 100%;

        &--med {
            width: 40%;
        }
    }
}
