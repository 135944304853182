/*------------------------------------*\
    # component.producers
\*------------------------------------*/

.c-producers {
    &__card {
        position: relative;

        /* height: 80vh; */
        padding-right: calc($unit * 2);

        overflow: auto;
    }

    &__wrapper {
        width: 100%;
        margin-bottom: calc($unit * 4);

        background-color: $color-background-primary;
        border-radius: $radius-base;

        overflow: hidden;
        overflow-x: auto;

        & .ag-theme-alpine {
            padding: calc($unit * 2) calc($unit * 3);
        }

        &.isCollapsed {
            & .c-producers__item {
                border-bottom: 1px solid color($color-text-light a(0.3));
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }
        }
    }

    &__header {
        position: sticky;
        top: 0;

        display: flex;
        justify-content: space-between;
        width: 300%;

        z-index: 1;

        margin-bottom: calc($unit * 3);
        padding-left: calc($unit * 6);

        background-color: $elevation-08;

        font-size: $type-sml;
        line-height: 20px;

        font-weight: $type-weight-semibold;

        @media (--screen-from-med) {
            width: 130%;
        }

        @media (--screen-from-xxlrg) {
            width: 115%;
        }

        @media (--screen-from-huge) {
            width: 100%;
        }
    }

    &__body {
        width: 300%;

        @media (--screen-from-med) {
            width: 130%;
        }

        @media (--screen-from-xxlrg) {
            width: 115%;
        }

        @media (--screen-from-huge) {
            width: 100%;
        }
    }

    &__item {
        display: flex;
        justify-content: space-between;
        align-items: center;

        font-size: $type-sml;
        line-height: 20px;
        border-radius: $radius-base;

        transition: background-color 200ms $transition;

        &:hover {
            background-color: $elevation-08;
        }

        &__col {
            display: inline-block;
            width: 100%;
            min-width: 12%;

            padding: calc($unit * 4) 0;
            padding-right: calc($unit * 2);

            &--payout {
                font-weight: $type-weight-semibold;
                color: $color-success;
            }

            &:first-child {
                padding-left: calc($unit * 4);
            }

            &:last-child {
                padding-right: calc($unit * 4);
            }
        }

        &__icon {
            display: flex;
            justify-content: center;
            min-width: calc($unit * 10);
        }

        &:last-child {
            & .c-producers__item__col {
                border-bottom: 0;
            }
        }
    }
}
