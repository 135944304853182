/*------------------------------------*\
    # component.pagination
\*------------------------------------*/

.c-pagination {
    display: flex;
    justify-content: center;
    align-items: baseline;
    flex-wrap: wrap;

    padding-bottom: $unit;
    margin-top: calc($unit * 3);

    @media (--screen-from-sml) {
        justify-content: space-between;

        flex-wrap: nowrap;
        margin-bottom: 0;
    }

    &__pagination {
        margin: 0 auto;

        @media (--screen-from-sml) {
            margin: 0;
        }
    }

    &__list {
        display: flex;
        justify-content: center;
        align-items: center;

        margin-bottom: calc($unit * 3);

        @media (--screen-from-sml) {
            justify-content: flex-start;
        }

        @media (--screen-from-med) {
            margin-bottom: 0;
        }

        & li span {
            color: $color-text-light;
        }
    }

    &__total {
        display: flex;
        align-items: center;

        margin: 0 auto;

        color: $white;
        font-size: $type-sml;
        line-height: 20px;

        @media (--screen-from-sml) {
            margin: 0;
        }

        & select {
            padding: calc($unit * 2) calc($unit * 2);
            padding-right: calc($unit * 6);
            margin-right: calc($unit * 4);

            color: $white;
            background-color: transparent;
            border-radius: $radius-base;

            background-image: svg-load(
                "./../postcss-svg/defaults/icon--arrow-down.svg",
                fill: color($color-text-light a(0.5))
            );
            background-size: calc($unit * 3);
            background-repeat: no-repeat;
            background-position: calc(100% - $unit * 2) center;

            -webkit-appearance: none;
            -moz-appearance: none;

            &:focus {
                outline: 0;
                border-radius: 6px;
                box-shadow: 0 0 0 2px $color-secondary;
            }

            & option {
                color: $color-text-light;
                background: $elevation-04;
            }
        }

        &__label {
            margin-right: calc($unit * 4);

            font-size: $type-sml;
            line-height: 20px;
            font-weight: $type-weight-semibold;
            text-transform: uppercase;
        }
    }

    &__item {
        display: flex;
        justify-content: center;
        align-items: center;

        min-width: calc($unit * 7);
        min-height: calc($unit * 7);

        margin-right: $unit;

        border-radius: $radius-full;

        &:focus {
            outline: 0;
        }

        &--link {
            color: $color-text-light;
            border: none;
            background: transparent;

            font-size: responsive $type-sml $type-base;
            font-range: 320px 1440px;

            line-height: responsive 20px 24px;
            line-height-range: 320px 1440px;

            cursor: pointer;
            transition: all 300ms $transition;

            &:hover {
                color: $color-primary;
            }

            &:focus {
                outline: 0;
            }
        }

        &--active {
            background-color: $color-primary;
            min-width: calc($unit * 7.5);
            max-width: calc($unit * 7.5);

            min-height: calc($unit * 7.5);
            max-height: calc($unit * 7.5);

            & .c-pagination__item--link {
                color: $color-text-light;
            }
        }

        &--prev,
        &--next {
            & .c-pagination__item--link {
                display: flex;
                align-items: baseline;

                padding: $unit calc($unit * 1.5);

                color: color($color-text-light a(0.4));

                border: 1px solid color($color-text-light a(0.4));
                border-radius: $radius-base;

                &:hover {
                    border: 1px solid color($color-text-light a(0.7));
                    color: color($color-text-light a(0.7));
                }

                @media (--scren-from-sml) {
                    padding: $unit calc($unit * 3);
                }
            }
        }

        &--prev {
            & .sr-only {
                margin-left: calc($unit * 2);
            }

            @media (--scren-from-sml) {
                margin-left: calc($unit * 3);
            }
        }

        &--next {
            & .sr-only {
                margin-right: calc($unit * 2);
            }

            @media (--scren-from-sml) {
                margin-right: calc($unit * 3);
            }
        }
    }
}
