/*------------------------------------*\
    # component.c-analytics
\*------------------------------------*/

.c-analytics {
    &__title {
        display: inline-flex;
        align-items: center;

        margin-bottom: calc($unit * 2.5);

        font-weight: $type-weight-bold;
        font-size: responsive $type-sml $type-base;
        font-range: 320px 1440px;

        line-height: responsive 20px 24px;
        line-height-range: 320px 1440px;

        color: $white;
    }

    &__header {
        display: flex;
        width: 100%;

        padding-bottom: calc($unit * 2);
        margin-bottom: calc($unit * 2);

        border-bottom: 1px solid color($color-text-light a(0.3));

        &__item {
            font-size: $type-sml;
            line-height: 20px;
            font-weight: $type-weight-semibold;

            border-right: 1px solid color($color-text-light a(0.3));
        }
    }

    &__list {
        max-height: calc($unit * 120);
        padding-right: calc($unit * 2);

        overflow-y: auto;

        &--med {
            max-height: calc($unit * 80);
        }

        &__label {
            position: absolute;

            font-size: $type-tny;
            line-height: 16px;

            color: color($color-text-light a(0.6));

            @media (--screen-from-sml) {
                display: none;
            }
        }

        &__item {
            display: flex;
            justify-content: space-between;
            align-items: center;

            width: 100%;

            margin-bottom: calc($unit * 3);

            font-size: responsive $type-sml $type-base;
            font-range: 320px 1440px;

            line-height: responsive 20px 24px;
            line-height-range: 320px 1440px;

            &:last-child {
                margin-bottom: 0;
            }

            &--secondary {
                justify-content: flex-start;

                padding-bottom: calc($unit * 4);
                border-bottom: 1px solid color($color-text-light a(0.3));

                & .c-analytics__list__title--sml {
                    @media (--screen-from-med) {
                        max-width: 350px;
                    }
                }

                &:last-child {
                    padding-bottom: 0;
                    border-bottom: 0;
                }
            }

            &--position {
                display: flex;
                flex-direction: column;
                align-items: center;

                min-width: calc($unit * 8);
                width: 7%;

                margin-right: calc($unit * 4);

                @media (--screen-from-sml) {
                    min-width: calc($unit * 12);
                }
            }

            &--name {
                width: 66%;

                display: flex;
                align-items: center;
            }

            &--prev-position {
                position: relative;

                min-width: 89px;
                width: 17%;

                & .c-analytics__list__label {
                    top: calc($unit * 4.5);
                    left: 0;
                }
            }

            &--score {
                position: relative;

                min-width: 60px;
                width: 12%;

                & .c-analytics__list__label {
                    top: calc($unit * 5.5);
                    right: calc($unit * 1.5);
                }
            }
        }

        &__information {
            display: flex;
            flex-direction: column;

            width: 100%;

            @media (--screen-from-sml) {
                flex-direction: row;
                justify-content: space-between;
            }

            &__stats {
                display: flex;
                align-items: center;
                justify-content: space-between;

                padding-right: calc($unit * 2);
            }
        }

        &__title {
            max-width: 240px;

            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            &--sml {
                max-width: calc($unit * 42);
            }

            &--lrg {
                max-width: 94%;
            }
        }

        &__img {
            min-width: 50px;
            width: 50px;
            height: 50px;

            margin-right: calc($unit * 5);

            border: 1px solid color($color-text-light a(0.35));
        }

        &__count {
            min-width: 60px;
            width: 12%;
            padding-right: $unit;

            text-align: right;

            font-weight: $type-weight-semibold;
        }
    }

    &__stats {
        display: flex;
        align-items: center;

        margin-right: $unit;

        font-weight: $type-weight-semibold;
        font-size: $type-sml;
        line-height: 20px;

        &--med {
            font-size: 32px;
            line-height: 1;
        }

        &--lrg {
            font-size: 52px;
            line-height: 1;
        }

        &--positive {
            color: $color-success;
        }

        &--negative {
            color: $charm;
        }

        &--neutral {
            color: color($color-text-light a(0.6));
        }
    }

    &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;

        padding: calc($unit * 1) calc($unit * 3);
        margin-bottom: calc($unit * 4);

        border-radius: $radius-base;
        transition: all 300ms ease-in-out;
        cursor: pointer;
        border: 1px solid transparent;

        &:last-child {
            margin-bottom: 0;
        }

        &:hover {
            background-color: color($elevation-08 a(1));
        }

        &--20 {
            width: 20%;
            border-right: 0;
        }

        &--25 {
            width: 25%;
            border-right: 0;
        }

        &--30 {
            width: 30%;
            border-right: 0;
        }

        &--position {
            min-width: 52px;
            width: 7%;

            @media (--screen-from-sml) {
                min-width: 66px;
                padding-left: calc($unit * 2);
            }
        }

        &--name {
            padding-left: calc($unit * 4);
            width: 66%;

            &--full {
                flex: 1;
            }
        }

        &--prev-position {
            min-width: 80px;
            width: 17%;

            padding-left: calc($unit * 4);
        }

        &--score {
            min-width: 78px;
            width: 12%;

            padding-left: calc($unit * 4);
            border-right: 0;
        }
    }

    &__card {
        position: relative;
        padding: calc($unit * 4);

        border-radius: $radius-base;
        background-color: $color-background-primary;

        &--secondary {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &__chart {
            display: flex;
            align-items: center;
            justify-content: center;

            @media (--screen-from-sml) {
                justify-content: flex-end;
            }
        }

        @media (--screen-from-med) {
            height: 100%;
            max-height: calc($unit * 65);
        }

        &--group {
            position: relative;
            padding: calc($unit * 4);

            margin-bottom: calc($unit * 4);

            border-radius: $radius-base;
            background-color: $color-background-primary;
        }
    }

    &__total {
        display: inline-block;
        min-width: calc($unit * 13);

        margin-left: calc($unit / 2);

        color: $color-text-light;

        &--primary {
            margin-bottom: $unit;

            color: $color-primary;
        }
    }
}
