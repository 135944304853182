/*------------------------------------*\
    # component.c-btn
\*------------------------------------*/

.c-btn {
    /**
     * Button base
    */
    /* To keep the button inline, and flex container at the same time */
    display: inline-flex;
    align-items: center;
    align-self: center;
    justify-content: center;

    touch-action: manipulation;
    user-select: none;

    cursor: pointer;
    white-space: nowrap;
    text-decoration: none;
    text-align: center;

    &:focus {
        outline: 0;
    }

    /**
     * Button base project styling
    */
    min-width: 100px;
    font-family: $ff-sans;
    font-weight: $type-weight-medium;
    border-radius: $radius-base;
    transition: all 300ms $transition;

    /**
     * Button sizes
    */
    &--xsml {
        min-width: 40px;
        max-width: 40px;
        font-size: $type-sml;
        padding: calc($unit * 1) calc($unit * 3);
    }

    &--sml {
        min-width: 50px;
        font-size: $type-sml;
        padding: calc($unit * 1) calc($unit * 3);
    }

    &--base {
        font-size: $type-base;
        padding: calc($unit * 2) calc($unit * 5);
    }

    &--med {
        font-size: $type-base;
        padding: calc($unit * 3) calc($unit * 8);
    }

    &--lrg {
        font-size: $type-med;
        padding: calc($unit * 4) calc($unit * 9);
    }

    &--full {
        width: 100%;
    }

    &--alt {
        min-width: 50px;
        font-size: $type-sml;
        padding: calc($unit * 1) calc($unit * 3);

        @media (--screen-from-sml) {
            font-size: $type-base;
            padding: calc($unit * 2) calc($unit * 5);
        }
    }

    &--icon {
        display: flex;
        align-items: center;
        justify-content: center;

        width: calc($unit * 8);
        min-width: calc($unit * 8);
        min-height: calc($unit * 8);

        padding: 0;

        border-radius: $radius-full;
        border: 1px solid transparent;
        background-color: transparent;

        &:hover {
            background-color: $elevation-08;
        }
    }

    /**
     * Button styles
    */
    &--primary {
        background: $color-primary;
        color: $color-text-light;
        border: 1px solid transparent;

        &:hover {
            color: color($color-text-light a(0.9));
            background: color($color-primary blackness(+10%));
        }

        &:focus {
            color: color($color-text-light a(0.9));
            outline: 0;
            border-radius: $radius-base;
            box-shadow: 0px 0px 0px 2px $color-secondary inset;
        }
    }

    &--secondary {
        background: $color-secondary;
        color: white;
        border: 1px solid transparent;

        &:hover {
            background: color($color-secondary blackness(+10%));
        }
    }

    &--success {
        border: 1px solid transparent;
        background-color: $color-success;
        color: $color-text-dark;

        &:hover {
            background-color: color($color-success a(0.9));
            color: $color-text-dark;
        }
    }

    &--warning {
        border: 1px solid transparent;
        background-color: $color-warning;
        color: $color-text-dark;

        &:hover {
            background-color: color($color-warning a(0.9));
            color: $color-text-dark;
        }
    }

    &--negative {
        border: 1px solid transparent;
        background: $white;
        color: $color-text-light;

        &:hover,
        &:focus {
            border: 1px solid $white;
            background: transparent;
            color: $white;
        }
    }

    &--light {
        border: 1px solid $white;
        background-color: $white;
        color: $color-primary;

        &:hover,
        &:focus {
            background-color: color($white a(0.9));
            color: $color-primary;
        }
    }

    &--neutral {
        background: $grey-6;
        color: $white;
        border: 1px solid transparent;

        &:hover {
            background: color($grey-6 blackness(+10%));
        }

        &:focus {
            outline: 0;
            border-radius: 2px;
            box-shadow: 0 0 0 2px $color-secondary;
        }
    }

    &--status {
        &--sml {
            min-width: calc($unit * 8.75);
            max-width: calc($unit * 8.75);
            height: calc($unit * 8.75);

            border-radius: 50%;
        }

        &--enabled {
            border: 1px solid transparent;
            background-color: $color-success;
            color: $white;

            &:hover {
                background-color: color($color-success a(0.7));
            }
        }

        &--disabled {
            border: 1px solid transparent;
            background-color: $charm;
            color: $white;

            &:hover {
                background-color: color($charm a(0.7));
            }
        }
    }

    &--outline {
        background-color: transparent;
        color: $white;
        border: 1px solid $white;

        &:hover {
            border: 1px solid color($white a(0.7));
        }

        &:focus {
            outline: 0;
            border-radius: $radius-base;
            box-shadow: 0px 0px 0px 2px $color-secondary inset;
        }

        &[disabled] {
            border: 1px solid $white !important;
            color: $white;

            &:hover {
                color: color($white a(0.7));
                border: 1px solid color($white a(0.7));
            }
        }

        &--enabled {
            border: 1px solid $color-success;
            color: $color-success;

            &:hover {
                border: 1px solid color($color-success a(0.7));
                color: color($color-success a(0.7));
            }
        }
    }

    &--ghost {
        background-color: transparent;
        color: $color-primary;
        border: 1px solid $color-primary;

        &:hover {
            color: color($color-primary a(0.7));
            background-color: transparent;
            border: 1px solid color($color-primary a(0.7));
            color: color($color-primary a(0.7));
        }

        &:focus {
            color: color($color-primary a(0.7));
            background-color: transparent;
            border: 1px solid $color-secondary;
            box-shadow: 0px 0px 0px 2px $color-secondary inset;
        }

        &[disabled] {
            border: 1px solid $color-primary !important;
            color: $color-primary;

            &:hover {
                color: color($color-primary a(0.7));
                border: 1px solid color($color-primary a(0.7));
            }
        }
    }

    &--anchor {
        background-color: transparent;
        color: $color-primary;
        border: 1px solid transparent;
        padding-left: 0;
        padding-right: 0;

        &:hover {
            background-color: transparent;
        }

        &:focus {
            background-color: transparent;
            border: 1px solid $color-secondary;
            box-shadow: 0px 0px 0px 2px $color-secondary inset;
        }

        &--underline {
            padding: 0;
            min-width: 0;

            text-decoration: underline;

            &:hover {
                color: color($color-primary a(0.8));
            }
        }
    }

    &--clear {
        position: absolute;
        right: calc($unit * 3);

        top: 46%;
        transform: translate(-50%, -50%);

        cursor: pointer;
    }

    &--rounded {
        border-radius: 50%;
        height: 40px;

        &:focus {
            border-radius: 50%;
        }

        &--sml {
            height: calc($unit * 7);
            min-height: calc($unit * 7);
            min-width: calc($unit * 7);
            max-width: calc($unit * 7);
        }
    }

    /* Special buttons */
    &.has-icon {
        position: relative;
        padding-right: calc($unit * 12);
        display: inline-flex;
        align-items: center;

        &:before,
        &:after {
            content: "";
            position: absolute;
            display: inline-block;
            background-color: currentColor;
            transition: all 250ms ease-out;
        }

        &:before {
            right: calc($unit * 5);
            width: calc($unit * 5);
            height: 1px;
        }

        &:after {
            right: calc($unit * 5);
            width: 7px;
            height: 7px;
            background: transparent;
            border-top: 1px solid currentColor;
            border-right: 1px solid currentColor;
            transform: rotate(45deg);
            transform-origin: center;
        }

        &:hover,
        &:focus {
            padding-right: calc($unit * 16);

            &:before {
                background-color: $white;
                width: calc($unit * 8);
            }

            &:after {
                border-top: 1px solid $white;
                border-right: 1px solid $white;
            }
        }
    }

    &.is-disabled,
    &[disabled] {
        pointer-events: none;
        opacity: 0.5;
        border-color: transparent;
    }

    &[aria-busy="true"],
    &.loading {
        overflow: hidden;
        opacity: 1;
        position: relative;
        cursor: not-allowed !important;
        pointer-events: none !important;
        background-color: color($color-primary a(0.5));

        & span {
            z-index: 1;
        }

        &:before {
            z-index: 0;
            content: "";
            position: absolute;
            bottom: 0;
            height: 4px;
            background-color: color($color-primary a(0.8));
            animation: buttonBusy 2000ms $transition infinite !important;
        }

        &:focus {
            box-shadow: none !important;
        }

        &:active {
            transform: none !important;
        }
    }
}

/* Busy button animation */
@keyframes buttonBusy {
    100% {
        left: 100%;
        width: 50%;
    }

    50% {
        left: 0;
        width: 100%;
    }

    0% {
        left: 0;
        width: 0;
    }
}
