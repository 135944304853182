/*------------------------------------*\
    # component.table
\*------------------------------------*/

.c-table {
    display: table;

    &--primary {
        table-layout: fixed;
        width: 100%;
        border-collapse: collapse;

        &__thead {
            display: table-header-group;
        }

        &__tbody {
            display: table-row-group;
        }

        &__tfoot {
            display: table-footer-group;
        }

        &__tr {
            display: table-row;

            &.u-cursor--pointer:hover {
                & .c-table--primary__td {
                    transition: all 300ms ease-out;
                    background-color: color($elevation-08 a(1));
                }
            }

            &:last-child {
                & .c-table--primary__td {
                    border-bottom: 0 !important;
                }
            }
        }

        &__th {
            display: table-cell;
            padding: calc($unit * 2) calc($unit * 4);

            text-align: right;

            background-color: $color-background-secondary;

            &:first-child {
                text-align: left;
            }
        }

        &__td {
            display: table-cell;
            padding: calc($unit * 2) calc($unit * 4);
            border-bottom: 1px solid color($color-border a(0.5));
            text-align: right;
            font-weight: $type-weight-regular;

            background-color: $color-background-primary;

            &:first-child {
                text-align: left;
            }
        }
    }
}
