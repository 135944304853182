/*------------------------------------*\
    # component.tabs
\*------------------------------------*/

.c-tabs {
    display: flex;
    margin-bottom: calc($unit * 5);

    &__navigation {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__item {
        padding: $unit calc($unit * 4);
        color: color($white-smoke a(0.6));

        font-weight: $type-weight-bold;
        font-size: responsive $type-sml $type-base;
        font-range: 320px 1440px;

        line-height: responsive 20px 24px;
        line-height-range: 320px 1440px;

        border-bottom: 2px solid $color-border;

        cursor: pointer;
        transition: all 300ms $transition;

        &:hover {
            color: color($white-smoke a(0.4));
        }

        &--active {
            color: $color-primary;

            border-bottom: 2px solid $color-primary;

            &:hover {
                color: color($color-primary a(0.8));
            }
        }
    }
}
