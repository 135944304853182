/*------------------------------------*\
    # component.collapse-panel
\*------------------------------------*/

.c-collapse-panel {
    position: relative;
    padding: 0 calc($unit * 3);
    margin-bottom: calc($unit * 3);

    font-size: responsive $type-sml $type-base;
    font-range: 320px 1440px;

    line-height: responsive 20px 24px;
    line-height-range: 320px 1440px;

    border-radius: $radius-base;
    background-color: $color-background-primary;

    overflow: hidden;

    &--secondary {
        margin-right: calc($unit * 2);
        margin-left: calc($unit * 2);
        background-color: $elevation-00;

        transition: 0.3s all;

        &:hover {
            background-color: color($elevation-00 a(0.6));
        }

        &:focus {
            outline: 0;
            border-radius: $radius-base;
            box-shadow: 0px 0px 0px 2px $color-secondary;
        }

        &.dragged {
            background-color: $elevation-02;
            border: 1px solid $elevation-08;
        }
    }

    @media (--screen-from-lrg) {
        padding: 0 calc($unit * 4);
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        padding: calc($unit * 4) 0;

        &--secondary {
            position: relative;
            padding-bottom: calc($unit * 6);
            margin-bottom: calc($unit * 7);

            border-bottom: 1px solid color($color-text-light a(0.3));

            @media (--screen-from-sml) {
                padding-bottom: calc($unit * 10);
            }

            &:after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 50%;

                transform: translate(-50%, 50%);

                width: calc($unit * 25);
                height: calc($unit * 2);
                background-color: $color-background-primary;
            }
        }
    }

    &__body {
        border-top: 1px solid color($color-text-light a(0.3));

        padding-top: calc($unit * 4);
        padding-bottom: calc($unit * 3);

        &--secondary {
            padding-bottom: calc($unit * 2);
        }

        &__label {
            margin-bottom: calc($unit * 1);

            font-size: $type-sml;
            line-height: 20px;
            color: color($color-text-light a(0.7));
        }

        &__description {
            max-width: 700px;

            & p,
            & p > span {
                color: $color-text-light !important;
                background-color: transparent !important;
                font-size: responsive $type-sml $type-base !important;
                font-range: 320px 1440px !important;

                line-height: responsive 20px 24px !important;
                line-height-range: 320px 1440px !important;
            }
        }

        &__link {
            font-size: responsive $type-sml $type-base;
            font-range: 320px 1440px;

            line-height: responsive 20px 24px;
            line-height-range: 320px 1440px;

            color: $color-primary;

            word-break: break-all;
        }
    }

    &__icon {
        position: absolute;
        content: "";
        position: absolute;
        bottom: 0;
        left: 50%;

        transform: translate(-50%, 50%);

        width: 24px;
        height: 24px !important;

        border-radius: $radius-full;
        background-color: $color-primary;

        z-index: 1;
    }
}
