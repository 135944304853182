.c-dropdown {
    &__trigger {
        position: relative;
        cursor: pointer;
    }

    &__content {
        position: fixed;

        padding: calc($unit * 4);

        border-radius: $radius-base;
        background-color: $elevation-00;
    }
}
