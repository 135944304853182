/*------------------------------------*\
    # component.select
\*------------------------------------*/

.c-select {
    position: relative;

    @media (--screen-from-lrg) {
        width: auto;
    }

    @media (--screen-from-sml) {
        margin-top: 0;
    }

    &--lrg {
        min-width: 0;
        width: auto;

        @media (--screen-from-sml) {
            margin-top: 0;
        }
    }

    &__input {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        font-size: responsive $type-sml $type-base;
        font-range: 320px 1440px;

        line-height: responsive 20px 24px;
        line-height-range: 320px 1440px;

        cursor: pointer;

        &:focus-visible {
            outline: 0;
            border-radius: 6px;
            box-shadow: 0 0 0 2px $color-secondary;
        }
    }

    &__item {
        display: flex;
        align-items: center;
        gap: calc($unit * 2);

        padding: calc($unit * 2) calc($unit * 4);

        font-size: $type-sml;
        line-height: 20px;

        z-index: 12;

        cursor: pointer;
        transition: 0.3s all;

        &:hover {
            background-color: $elevation-06;
        }

        &.focused {
            background-color: $elevation-02;
        }

        &.isDisabled {
            opacity: 0.6;
            background-color: color($elevation-06 a(1));
            cursor: not-allowed;
        }

        &--active,
        &--disabled {
            position: relative;

            &:before {
                content: "";
                position: absolute;
                width: calc($unit * 2.5);
                height: calc($unit * 2.5);

                right: -$unit;
                top: -$unit;

                border-radius: $radius-full;
            }
        }

        &--active {
            &:before {
                border: 1px solid $color-background-primary;
                background-color: $color-success;
            }
        }

        &--disabled {
            &:before {
                border: 1px solid color($white a(0.7));
                background-color: color($elevation-10 a(0.7));
            }
        }
    }

    &__label {
        max-width: 89px;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @media (--screen-from-xlrg) {
            max-width: 200px;
        }
    }

    &--date {
        & .c-select__item {
            &:first-child {
                border-bottom: 1px solid color($color-primary a(0.8));
            }
        }
    }

    &__dropdown {
        position: absolute;
        top: 36px;
        left: 0;

        width: 200px;
        max-height: 300px;

        border-radius: $radius-base;
        background-color: $elevation-08;

        overflow: hidden;
        overflow-y: auto;

        z-index: 12;

        &--secondary {
            right: 0;
            left: auto;
        }
    }

    &--secondary {
        & .c-select__dropdown {
            top: auto;
            bottom: calc($unit * 16);

            @media (--screen-from-sml) {
                top: 36px;
                bottom: auto;
            }
        }
    }
}
