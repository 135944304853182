/*------------------------------------*\
    # component.c-inputgroup
\*------------------------------------*/

.c-inputgroup {
    display: table;
    position: relative;
    border-collapse: collapse;
    width: 100%;

    &--main {
        display: table-cell;
        width: 100%;
    }

    &--aside {
        display: table-cell;
        width: 1%;
        white-space: nowrap;
        vertical-align: middle;
    }
}