/*------------------------------------*\
    # component.input
\*------------------------------------*/

.c-input {
    /**
     * Input base
     */
    display: inline-flex;
    width: 100%;

    vertical-align: middle;
    cursor: auto;
    touch-action: manipulation;
    appearance: none;
    transition: border-color 200ms;
    -webkit-appearance: none;
    border-radius: $radius-base;
    background-color: $white;

    /**
     * Input base project styling
     */
    color: $color-text-dark;
    font-family: $ff-sans;
    border: 1px solid $color-text-light;
    border-radius: $radius-base;
    transition: all 250ms $transition;
    caret-color: $color-text-light;

    scrollbar-width: thin;

    &::placeholder {
        color: color($color-text-dark a(0.8));
    }

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
        border: 1px solid $white;
        -webkit-text-fill-color: $color-text-light;
        -webkit-box-shadow: 0 0 0px 1000px #000 inset;
        transition: background-color 5000s ease-in-out 0s;
    }

    &:invalid {
        border: 1px solid $color-warning;
    }

    &:focus {
        outline: 0;
        border-radius: 6px;
        box-shadow: 0 0 0 2px $color-secondary;
    }

    &[disabled] {
        color: color($color-text-light a(0.3)) !important;

        background-color: $elevation-02;
        border-color: color($color-text-light a(0.3));
    }

    /**
     * Input sizes
    */

    &--outline {
        /**
        * Input base project styling
        */
        color: $color-text-light;
        border: 1px solid $color-text-light;
        background-color: transparent;

        &::placeholder {
            color: color($color-text-light a(0.6));
        }

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus {
            border: 1px solid $white;
            -webkit-text-fill-color: $color-text-light;
            -webkit-box-shadow: 0 0 0px 1000px $elevation-02 inset;
        }

        &:focus {
            background-color: color($color-text-light a(0.1));
            border: 1px solid $color-primary;
        }
    }

    /**
     * Input sizes
     */
    &--sml {
        font-size: $type-sml;
        height: calc($unit * 8.5);
        line-height: calc($unit * 8);
        padding: calc($unit * 4) calc($unit * 3);
    }

    &--base {
        font-size: $type-base;
        padding: calc($unit * 2) calc($unit * 3);
    }

    &--med {
        font-size: $type-base;
        padding: calc($unit * 3) calc($unit * 4);
    }

    &--lrg {
        font-size: $type-lrg;
        height: calc($unit * 12);
        line-height: calc($unit * 12);
    }

    &--full {
        width: 100%;
        max-width: 100% !important;
    }

    /**
     * Input types
     */
    &--text {
        width: 100%;
    }

    &--textarea {
        display: block;
        width: 100%;
        max-width: 100%;

        line-height: 20px;

        padding: calc($unit * 2) calc($unit * 4);

        resize: none;
    }

    &--dark {
        background-color: transparent;
        color: $color-text-light;

        border-color: $color-border;

        &::placeholder {
            color: color($color-text-light a(0.8));
        }
    }

    &--numeric {
        display: block;
        height: calc($unit * 8.5);

        padding: calc($unit * 4) calc($unit * 3);

        font-size: $type-sml;
        line-height: calc($unit * 8);

        &--sml {
            width: calc($unit * 17.5);
        }

        &--base {
            height: calc($unit * 11);
        }
    }

    &--select,
    &--date,
    &--search,
    &--time,
    &--currency {
        padding-right: calc($unit * 6);

        background-repeat: no-repeat;
        background-size: calc($unit * 4) calc($unit * 4);
        background-position: calc(100% - $unit * 4) center;
        vertical-align: middle;
    }

    &--date {
        background-image: svg-load(
            "./../postcss-svg/defaults/icon--calendar.svg",
            fill: color($color-text-light a(0.5))
        );
    }

    &--search {
        min-width: 230px;
        max-width: 290px;
        padding-right: calc($unit * 10);

        background-image: svg-load("./../postcss-svg/defaults/icon--search.svg", fill: color($color-text-dark a(0.8)));

        caret-color: $color-text-dark;

        &:focus {
            padding-right: calc($unit * 10);
        }

        &.clear {
            background-image: svg-load(
                "./../postcss-svg/defaults/icon--search.svg",
                fill: color($color-text-dark a(0))
            );

            transition: all 30ms $transition;
        }

        &--lrg {
            min-width: 230px;

            @media (--screen-from-sml) {
                min-width: 290px;
            }
        }
    }

    &--select {
        background-image: svg-load(
            "./../postcss-svg/defaults/icon--arrow-down.svg",
            fill: color($color-text-light a(0.5))
        );
    }

    &--time {
        background-image: svg-load("./../postcss-svg/defaults/icon--clock.svg", fill: color($color-text-light a(0.5)));
    }

    &--currency {
        background-image: svg-load(
            "./../postcss-svg/defaults/icon--currency.svg",
            fill: color($color-text-light a(0.5))
        );
    }

    &--check,
    &--radio,
    &--check--secondary {
        opacity: 0;
        position: absolute;

        & + label {
            position: relative;
            display: inline-block;
            cursor: pointer;
            font-size: $type-sml;
            padding-left: calc($unit * 5);

            &:before {
                content: "";
                position: absolute;
                left: 0;
                top: 1px;

                width: calc($unit * 4);
                height: calc($unit * 4);

                padding: $unit;

                border: 2px solid color(white blackness(+30%));
                border-radius: 3px;

                transition: all 300ms ease-out;
            }

            &:after {
                /* z-index: 1; */
                content: "";
                opacity: 0;
                position: absolute;
                left: 3px;
                top: 5px;
                display: inline-block;

                width: calc($unit * 2.5);
                height: calc($unit * 2.5);

                transition: all 300ms ease-out;
            }
        }

        &:checked {
            & + label {
                &:before {
                    background-color: $color-secondary;
                    border-color: $color-secondary;
                }
                &:after {
                    background-repeat: no-repeat;
                    background-position: center center;
                }
            }
        }

        &:focus {
            & + label {
                &:before {
                    outline: 2px dotted $color-secondary;
                    border-radius: $radius-base;
                }
            }
        }
    }

    &--check {
        &:before {
            left: 0;
            top: 1px;

            width: calc($unit * 4);
            height: calc($unit * 4);

            padding: $unit;

            border: 2px solid color(white blackness(+30%));
            border-radius: 3px;
        }

        &:after {
            left: 3px;
            top: 5px;
        }

        &:checked {
            &:before {
                & + label {
                    background-color: $color-secondary;
                    border-color: $color-secondary;
                }
            }

            & + label {
                &:after {
                    top: 4px;
                    opacity: 1;
                    transform: rotate(0deg);
                    background-image: svg-load("./../postcss-svg/defaults/icon--check.svg", fill: $color-text-dark);
                }
            }
        }

        &[disabled] {
            & + label {
                color: color($color-text-light a(0.5));
                cursor: default;

                &:before {
                    border: 1px solid color($color-text-light a(0.2));
                    background-color: color($color-text-light a(0.2));
                }
            }
        }

        &--secondary {
            & + label {
                &:before {
                    width: calc($unit * 6);
                    height: calc($unit * 6);

                    border-color: $color-border;
                    background-color: $color-border;
                }
            }

            &:checked {
                & + label {
                    &:before {
                        width: calc($unit * 6);
                        height: calc($unit * 6);

                        background-color: $color-warning;
                        border-color: $color-warning;
                    }

                    &:after {
                        top: 4px;
                        opacity: 1;
                        transform: rotate(0deg);
                        background-image: none;
                    }
                }
            }

            &:hover {
                & + label {
                    &:before {
                        border-color: color($grey-3);
                        background-color: color($grey-3);
                    }
                }

                &:checked {
                    & + label {
                        &:before {
                            border-color: color($color-warning a(0.3));
                            background-color: color($color-warning a(0.6));
                        }
                    }
                }
            }
        }

        &--tertiary {
            & + label {
                &:before {
                    left: 0;
                    top: calc(-$unit * 2);

                    width: calc($unit * 4);
                    height: calc($unit * 4);

                    padding: $unit;

                    border: 2px solid color(white blackness(+30%));
                    border-radius: calc($unit * 4);
                }

                &:after {
                    left: 3px;
                    top: 5px;
                }
            }

            &:checked {
                & + label {
                    &:before {
                        background-color: $color-secondary;
                        border-color: $color-secondary;
                    }

                    &:after {
                        top: -$unit;
                        opacity: 1;
                        transform: rotate(0deg);
                        background-image: svg-load("./../postcss-svg/defaults/icon--check.svg", fill: $color-text-dark);
                    }
                }
            }
        }
    }

    &--radio {
        & + label {
            position: relative;
            &:before {
                border-radius: 50%;
            }

            &:after {
                content: "";
                opacity: 0;
                display: inline-block;
                position: absolute;
                top: 4px;
                left: 3px;

                width: calc($unit * 2.5);
                height: calc($unit * 2.5);

                background-color: $color-text-dark;
                border-radius: $radius-full;
                transform: scale(0);
                transition: all 300ms ease-out;
            }
        }

        &:checked {
            & + label {
                &:after {
                    opacity: 1;
                    transform: scale(1);
                }
            }
        }

        &:focus {
            & + label {
                &:before {
                    box-shadow: $z-depth-1;
                }
            }
        }
    }

    &--radio-btn {
        opacity: 0;
        position: absolute;

        &.c-input--radio-btn--first {
            & + label {
                border-top-left-radius: $radius-base;
                border-bottom-left-radius: $radius-base;
            }
        }

        &.c-input--radio-btn--last {
            & + label {
                border-top-right-radius: $radius-base;
                border-bottom-right-radius: $radius-base;
            }
        }

        &.active {
            & + label {
                background: $color-primary;
                color: $color-text-dark;
                border: 1px solid transparent;

                &:hover {
                    background: color($color-primary blackness(+10%));
                }

                &:focus {
                    outline: 0;
                    border-radius: $radius-base;
                    box-shadow: 0px 0px 0px 2px $color-secondary inset;
                }
            }
        }

        & + label {
            font-size: $type-base;
            padding: calc($unit * 1) calc($unit * 3);

            background-color: transparent;
            color: $color-primary;
            border: 1px solid $color-primary;

            cursor: pointer;
            transition: all 300ms $transition;

            &:hover {
                background-color: transparent;
                border: 1px solid color($color-primary a(0.7));
            }

            &:focus {
                background-color: transparent;
                border: 1px solid $color-secondary;
                box-shadow: 0px 0px 0px 2px $color-secondary inset;
            }
        }
    }

    &--warning {
        border: 1px solid $color-warning;
    }
}
