/*------------------------------------*\
    # utils.width
\*------------------------------------*/

.u-width {
    &--50 {
        width: 50%;

        &--px {
            width: 50px;
        }
    }

    &--100 {
        width: 100%;

        &--px {
            width: 100px;
        }

        &--from {
            &-med {
                @media (--screen-from-med) {
                    width: 100%;
                }
            }
        }

        &--to {
            &-lrg {
                @media (--screen-to-lrg) {
                    width: 100%;
                }
            }
        }
    }

    &--200 {
        &--min {
            min-width: 200px;
        }
    }

    &--300 {
        &--px {
            width: 300px;
        }

        &--max {
            max-width: 300px;
        }

        &--min {
            min-width: 300px;
        }
    }

    &--400 {
        &--px {
            width: 400px;
        }
    }

    &--500 {
        &--max {
            max-width: 500px;
        }
    }
}
