/*------------------------------------*\
    # components.loader
\*------------------------------------*/

.loader {
    &--overlay {
        z-index: 14;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        background-color: color($color-text-dark a(0.6));

        &--secondary {
            background-color: $color-text-dark;
        }

        & .center {
            position: absolute;
            top: calc(50% - 32px);
            left: calc(50% - 32px);
        }
    }
}

.lds-ripple {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-ripple div {
    position: absolute;
    border: 4px solid #fff;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1500ms cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
}

@keyframes lds-ripple {
    0% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        top: 0px;
        left: 0px;
        width: 72px;
        height: 72px;
        opacity: 0;
    }
}
