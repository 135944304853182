/*------------------------------------*\
    # component.page
\*------------------------------------*/
$aside-width: 200px;
$header-height: 77px;

.c-page {
    &__header {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;

        display: flex;
        align-items: center;
        justify-content: space-between;

        height: $header-height;
        padding: 0 calc($unit * 4);
        padding-right: calc($unit * 17);

        background-color: $elevation-06;

        z-index: 4;
        transition: 0.3s all;

        @media (--screen-from-lrg) {
            left: $aside-width;
            padding: 0 calc($unit * 6);
        }

        &--secondary {
            position: relative;
            left: 0;
            height: auto;

            padding: calc($unit * 4) 0;
            margin-bottom: calc($unit * 4);

            background-color: transparent;

            @media (--screen-from-med) {
                border-bottom: 1px solid color($white a(0.2));
            }
        }

        &--top {
            display: flex;
            align-items: center;
            justify-content: space-between;

            width: 100%;
            position: relative;
        }
    }

    &__main {
        margin-top: $header-height;
        margin-left: 0;

        padding: calc($unit * 4);
        margin-bottom: calc($unit * 18);

        @media (--screen-from-sml) {
            margin-bottom: 0;
            padding-top: calc($unit * 8);
        }

        @media (--screen-from-lrg) {
            margin-left: $aside-width;
            padding: calc($unit * 6);
        }

        &--secondary {
            padding: 0 calc($unit * 4);
            padding-bottom: calc($unit * 4);
            margin-top: 0;
            margin-bottom: 0;

            @media (--screen-from-sml) {
                padding: 0 calc($unit * 6);
                padding-bottom: calc($unit * 6);
            }
        }
    }
}
