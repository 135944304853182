/*------------------------------------*\
    # utils.icon
\*------------------------------------*/

.u-icon {
    display: inline-block;

    background-repeat: no-repeat;
    background-position: center center;
    vertical-align: middle;
    background-size: contain;

    transition: all 250ms ease-out;

    &--sml {
        min-width: calc($unit * 2);
        height: calc($unit * 2);
    }

    &--base {
        min-width: calc($unit * 3);
        height: calc($unit * 3);
        background-size: calc($unit * 3);
    }

    &--med {
        min-width: calc($unit * 4);
        height: calc($unit * 4);
        background-size: calc($unit * 4);

        &--alt {
            min-width: calc($unit * 5);
            height: calc($unit * 5);
            background-size: calc($unit * 5);
        }
    }

    &--lrg {
        width: calc($unit * 6);
        height: calc($unit * 6);
    }

    &--xlrg {
        width: calc($unit * 8);
        height: calc($unit * 8);
    }

    &--xxlrg {
        width: calc($unit * 12);
        height: calc($unit * 12);
    }

    &--xxxlrg {
        width: calc($unit * 17);
        height: calc($unit * 17);
    }

    &--huge {
        width: calc($unit * 25);
        height: calc($unit * 25);
    }

    &--xhuge {
        width: calc($unit * 35);
        height: calc($unit * 35);
    }

    &--edit {
        background-image: svg-load("../postcss-svg/edit.svg", fill: $white);
    }

    &--back {
        background-image: svg-load("../postcss-svg/back.svg", stroke: $color-primary);
    }

    &--drag {
        background-image: svg-load("../postcss-svg/drag.svg", fill: $color-text-light);
    }

    &--premium {
        background-image: svg-load("../postcss-svg/premium.svg", stroke: #c99f71);
    }

    &--info {
        background-image: svg-load("../postcss-svg/info-2.svg", stroke: $color-primary);

        &--white {
            background-image: svg-load("../postcss-svg/info-2.svg", stroke: $white);
        }
    }

    &--profile {
        background-image: svg-load("../postcss-svg/profile.svg", stroke: $white);
    }

    &--exit {
        background-image: svg-load("../postcss-svg/exit.svg", fill: $color-primary);
    }

    &--close {
        background-image: svg-load("../postcss-svg/close.svg", stroke: $white);

        &--primary {
            background-image: svg-load("../postcss-svg/close.svg", stroke: $color-text-dark);
        }
    }

    &--note-list {
        background-image: svg-load("../postcss-svg/note-list.svg", stroke: $color-primary);
    }

    &--section {
        background-image: svg-load("../postcss-svg/section.svg", stroke: $white);
    }

    &--retry {
        background-image: svg-load("../postcss-svg/retry.svg", fill: $white);
    }

    &--add-list {
        background-image: svg-load("../postcss-svg/add-list.svg", stroke: $white);
    }

    &--upload {
        background-image: svg-load("../postcss-svg/upload.svg", stroke: $color-primary);

        &--sml {
            background-image: svg-load("../postcss-svg/upload-sml.svg", stroke: $color-primary);
        }
    }

    &--upload-audio {
        background-image: svg-load("../postcss-svg/upload-audio.svg", stroke: $color-primary);
    }

    &--empty-state {
        width: 250px;
        height: 250px;
        background-size: contain;
        background-image: svg-load("../postcss-svg/empty-state.svg");
    }

    &--empty-download {
        width: 200px;
        height: 200px;
        background-size: contain;
        background-image: svg-load("../postcss-svg/empty-download.svg", fill: $white);
    }

    &--radio {
        background-image: svg-load("../postcss-svg/devices/radio.svg", stroke: $color-primary);

        &--dark {
            background-image: svg-load("../postcss-svg/devices/radio.svg", stroke: $color-text-dark);
        }

        &--light {
            background-image: svg-load("../postcss-svg/devices/radio.svg", stroke: $white);
        }

        &--sml {
            background-image: svg-load("../postcss-svg/devices/radio-sml.svg", stroke: $white);
        }
    }

    &--mobile {
        background-image: svg-load("../postcss-svg/devices/mobile.svg", stroke: $color-text-dark);

        &--light {
            background-image: svg-load("../postcss-svg/devices/mobile.svg", stroke: $white);
        }

        &--sml {
            background-image: svg-load("../postcss-svg/devices/mobile-sml.svg", fill: $white);
        }
    }

    &--question {
        background-image: svg-load("../postcss-svg/question.svg", fill: $color-tertiary);
        cursor: pointer;
    }

    &--radio-lrg {
        width: 140px;
        height: 169px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        background-image: svg-load("../postcss-svg/devices/radio-lrg.svg");
    }

    &--analytics {
        width: 6px;
        height: 14px;
        background-size: calc($unit * 4);

        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;

        &--lrg {
            width: 24px;
            height: 24px;
            background-size: calc($unit * 2.5);
        }

        &--xlrg {
            width: 32px;
            height: 32px;
            background-size: calc($unit * 4);
        }

        &--up {
            background-image: svg-load("../postcss-svg/analytics-up.svg", fill: $color-success);
        }

        &--down {
            background-image: svg-load("../postcss-svg/analytics-down.svg", fill: $charm);
        }

        &--neutral {
            background-image: svg-load("../postcss-svg/minus.svg", stroke: $grey-1);
        }
    }

    &--link {
        background-image: svg-load("../postcss-svg/link.svg", stroke: $color-text-light);

        &--primary {
            background-image: svg-load("../postcss-svg/link.svg", stroke: $color-primary);
        }
    }

    &--payout {
        background-image: svg-load("../postcss-svg/payout.svg", stroke: $color-primary);
    }

    &--music-note {
        background-image: svg-load("../postcss-svg/music-note.svg", stroke: $color-text-light);
    }

    &--music-list {
        background-image: svg-load("../postcss-svg/music-list.svg", stroke: $color-text-light);
    }

    &--download {
        background-image: svg-load("../postcss-svg/download.svg", stroke: $color-text-light);
    }

    &--tag {
        background-image: svg-load("../postcss-svg/tag.svg", fill: $color-text-light);

        &--dark {
            background-image: svg-load("../postcss-svg/tag.svg", fill: $color-text-dark);
        }
    }

    &--circle {
        background-image: svg-load("../postcss-svg/circle.svg", stroke: $color-text-light);

        &--active {
            background-image: svg-load("../postcss-svg/circle.svg", fill: $color-success);
        }

        &--inactive {
            background-image: svg-load("../postcss-svg/circle.svg", fill: color($color-text-light a(0.6)));
        }
    }

    &--stations {
        background-image: svg-load("../postcss-svg/stations.svg", stroke: $color-text-light);

        &--opaque {
            background-image: svg-load("../postcss-svg/stations.svg", stroke: color($color-text-light a(0.6)));
        }
    }

    &--shuffle {
        background-image: svg-load("../postcss-svg/shuffles.svg", stroke: $color-text-light);
    }

    &--nigun {
        background-image: svg-load("../postcss-svg/navigation/nigun.svg", stroke: $color-text-light);
    }

    &--podcasts {
        background-image: svg-load("../postcss-svg/podcasts.svg", stroke: $color-text-light);

        &--opaque {
            background-image: svg-load("../postcss-svg/podcasts.svg", stroke: color($color-text-light a(0.6)));
        }

        &--primary {
            background-image: svg-load("../postcss-svg/podcasts.svg", stroke: color($color-primary a(0.6)));
        }
    }

    &--delete {
        background-image: svg-load("../postcss-svg/delete.svg", fill: $color-text-light);
    }

    &--checked {
        background-image: svg-load("../postcss-svg/checked.svg", fill: $color-success);
    }

    &--check {
        background-image: svg-load("../postcss-svg/icon-check.svg", stroke: $color-background-primary);
    }

    &--unchecked {
        background-image: svg-load("../postcss-svg/checked.svg", fill: color($white a(0.5)));
    }

    &--search {
        background-image: svg-load("../postcss-svg/defaults/icon--search.svg", fill: $color-text-light);
    }

    &--line-arrow {
        background-image: svg-load("../postcss-svg/line-arrow.svg", fill: $color-text-light);
    }

    &--arrow-down {
        background-image: svg-load("../postcss-svg/arrow-down.svg", stroke: $color-text-light);

        &--warning {
            background-image: svg-load("../postcss-svg/arrow-down.svg", stroke: $charm);
        }
    }

    &--arrow-up {
        background-image: svg-load("../postcss-svg/arrow-up.svg", stroke: $color-text-light);

        &--success {
            background-image: svg-load("../postcss-svg/arrow-up.svg", stroke: $color-success);
        }
    }

    &--arrow-right {
        background-image: svg-load("../postcss-svg/arrow-right.svg", stroke: $color-text-light);

        &--primary {
            background-image: svg-load("../postcss-svg/arrow-right-secondary.svg", stroke: $color-primary);
        }

        &--secondary {
            background-image: svg-load("../postcss-svg/arrow-right-secondary.svg", stroke: $color-text-light);
        }
    }

    &--caret-right {
        &--primary {
            background-image: svg-load("../postcss-svg/arrow-right.svg", stroke: $color-primary);
        }
    }

    &--arrow-left {
        background-image: svg-load("../postcss-svg/arrow-left.svg", stroke: $color-text-light);

        &--primary {
            background-image: svg-load("../postcss-svg/arrow-left.svg", stroke: $color-primary);
        }
    }

    &--arrow-fill-up {
        background-image: svg-load("../postcss-svg/arrow-fill-up.svg", stroke: $color-primary);
        background-repeat: no-repeat;
        background-position: center;
    }

    &--arrow-fill-down {
        background-image: svg-load("../postcss-svg/arrow-fill-down.svg", stroke: $color-primary);
        background-repeat: no-repeat;
        background-position: center;
    }

    &--switch-device {
        min-width: 30px;
        height: 30px;

        background-image: svg-load("../postcss-svg/switch-device.svg");
        background-repeat: no-repeat;
        background-position: center;
    }

    &--welcome {
        width: 300px;
        height: 300px;
        background-size: contain;
        background-image: svg-load("../postcss-svg/welcome.svg");
        background-repeat: no-repeat;
        background-position: center;

        &--sml {
            width: 150px;
            height: 150px;
        }
    }

    &--empty {
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;

        &--shuffle {
            background-image: svg-load("../postcss-svg/empty-shuffle.svg");
        }

        &--analytics {
            background-image: svg-load("../postcss-svg/analytics.svg");
        }

        &--sml {
            width: 90px;
            height: 90px;
        }

        &--lrg {
            width: 140px;
            height: 140px;

            background-size: 130px;
        }
    }

    &--power {
        &--enabled {
            background-image: svg-load("../postcss-svg/power.svg", fill: $color-success);
        }

        &--disabled {
            background-image: svg-load("../postcss-svg/power.svg", fill: $charm);
        }
    }

    /* Credit card icons */
    &--amex {
        background-image: svg-load("../postcss-svg/credit-cards/american.svg");
    }

    &--diners {
        background-image: svg-load("../postcss-svg/credit-cards/diners_club.svg");
    }

    &--discover {
        background-image: svg-load("../postcss-svg/credit-cards/discover.svg");
    }

    &--jcb {
        background-image: svg-load("../postcss-svg/credit-cards/jcb.svg");
    }

    &--mastercard {
        background-image: svg-load("../postcss-svg/credit-cards/mastercard.svg");
    }

    &--visa {
        background-image: svg-load("../postcss-svg/credit-cards/visa.svg");
    }

    &--custom-visa {
        background-image: svg-load("../postcss-svg/credit-cards/icon-visa.svg", fill: $white);
    }

    &--unionpay {
        background-image: svg-load("../postcss-svg/credit-cards/unionpay.svg");
    }

    &--other {
        background-image: svg-load("../postcss-svg/credit-cards/other.svg" fill: $white);
    }

    &--playlist-add {
        background-image: svg-load("../postcss-svg/add-to-playlist.svg" stroke: $color-primary);
    }

    &--cc-sync-outline {
        min-width: calc($unit * 8);
        max-width: calc($unit * 8);

        min-height: calc($unit * 8);
        max-height: calc($unit * 8);

        background-image: svg-load("../postcss-svg/credit-cards/cc-sync-outline.svg" stroke: $color-primary);
    }

    &--premium {
        &--disabled {
            background-image: svg-load("../postcss-svg/power.svg", fill: $charm);
        }

        &--enabled {
            background-image: svg-load("../postcss-svg/power.svg", fill: $color-success);
        }

        &--white {
            background-image: svg-load("../postcss-svg/power.svg", fill: $white);
        }
    }

    &--sync {
        background-size: 12px;
        background-image: svg-load("../postcss-svg/sync.svg", fill: $color-text-light);

        &--active {
            width: 32px;
            height: 32px;

            border-radius: $radius-full;
            background-color: $color-success;
            background-image: svg-load("../postcss-svg/sync.svg", fill: $color-background-primary);
            transition: all 550ms $transition;
        }

        &--inactive {
            width: 32px;
            height: 32px;

            border-radius: $radius-full;
            background-color: $elevation-10;
            background-image: svg-load("../postcss-svg/sync.svg", fill: $color-background-primary);
            transform: rotate(180deg);
            transition: all 550ms $transition;
        }
    }

    &--subscription {
        width: 100%;
        height: 180px;

        &--tales {
            background-image: svg-load("../postcss-svg/subscription/naki-tales.svg");
        }

        &--premium {
            background-image: svg-load("../postcss-svg/subscription/naki-premium.svg");
        }

        &--nigun {
            background-image: svg-load("../postcss-svg/subscription/naki-nigun.svg");
        }
    }

    &--plan {
        &--sml {
            min-width: calc($unit * 6);
            height: calc($unit * 6);
            background-size: calc($unit * 6);
        }

        &--base {
            min-width: calc($unit * 9);
            height: calc($unit * 9);
            background-size: calc($unit * 9);
        }

        &--med {
            min-width: calc($unit * 14);
            height: calc($unit * 14);
            background-size: calc($unit * 14);
        }

        /* &--tales {
            background-image: svg-load("../postcss-svg/subscription/plan/naki-tales.svg");
        } */

        &--premium {
            background-image: svg-load("../postcss-svg/subscription/plan/naki-premium.svg", stroke: #c99f71);
        }

        &--nigun {
            background-image: svg-load(
                "../postcss-svg/subscription/plan/naki-nigun.svg",
                stroke: $color-border-primary
            );
        }

        &--free {
            background-image: svg-load("../postcss-svg/subscription/plan/naki-free.svg", stroke: $color-success);
        }
    }

    &--chip {
        background-image: svg-load("../postcss-svg/defaults/icon-chip.svg");
    }

    &--image-replace {
        background-image: svg-load("../postcss-svg/image-replace.svg");
    }

    &--undo {
        background-image: svg-load("../postcss-svg/undo.svg");
    }

    &--trash {
        background-image: svg-load("../postcss-svg/trash.svg", fill: $color-warning);
    }

    &--expand {
        &--down {
            background-image: svg-load("../postcss-svg/expand-down.svg", fill: $color-text-dark);
        }

        &--top {
            background-image: svg-load("../postcss-svg/expand-top.svg", fill: $color-text-dark);
        }

        &--right {
            background-image: svg-load("../postcss-svg/expand-right.svg", fill: $color-text-dark);
        }

        &--left {
            background-image: svg-load("../postcss-svg/expand-left.svg", fill: $color-text-dark);
        }
    }
}
