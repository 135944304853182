/*------------------------------------*\
    # component.logo
\*------------------------------------*/

.c-logo {
    &__wrapper {
        display: flex;
        border-bottom: 1px solid color($white a(0.15));
        padding: calc($unit * 6);
    }
}