/*------------------------------------*\
    # component.react-joyride
\*------------------------------------*/

.react-joyride {
    &__tooltip {
        font-family: $ff-sans !important;
        font-size: $type-base !important;
        line-height: 24px !important;
    }
}
