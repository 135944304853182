/*------------------------------------*\
    # component.hero
\*------------------------------------*/

.c-hero {
    padding: calc($unit * 4) 0;

    &__copy {
        opacity: 0;
        visibility: hidden;
        transition: opacity, visibility 250ms $transition;


        @media (--screen-from-lrg) {
            opacity: 1;
            visibility: visible;
        }

        &--title {
            margin-bottom: calc($unit * 4);

            font-size: responsive $type-lrg $type-xlrg;
            font-range: 320px 1440px;

            line-height: responsive 40px 44px;
            line-height-range: 320px 1440px;
        }

        &--info {
            font-style: italic;
        }
    }
}