/*------------------------------------*\
    # plugin.ag
\*------------------------------------*/

.ag {
    &-theme-alpine {
        display: grid;
        grid-template-columns: 1fr;
        grid-auto-rows: 469px 1fr;

        &--sml {
            grid-auto-rows: 269px 1fr;
        }

        &--empty {
            & .ag-body-horizontal-scroll-viewport {
                display: none;
            }
        }

        &--secondary {
            & .ag-header {
                &-row {
                    & .ag-header-cell-label {
                        justify-content: flex-end;
                    }

                    & .ag-header-cell {
                        &:last-child {
                            justify-content: flex-end;
                        }
                    }
                }
            }

            & .ag-row {
                & .ag-cell {
                    &:last-child {
                        justify-content: flex-end;
                    }
                }
            }
        }

        & .ag-layout-auto-height {
            & .ag-center-cols-container,
            & .ag-center-cols-clipper {
                min-height: 0px !important;
            }
        }
    }

    &-root-wrapper {
        border: 0 !important;
        background-color: $color-background-primary !important;
    }

    &-dnd-ghost {
        display: flex !important;

        &-ghost-label {
            position: relative !important;
        }
    }

    &-header {
        background-color: $color-background-secondary !important;
        border-bottom: 0 !important;

        &-row {
            color: $color-text-light !important;
        }

        &-cell {
            &-moving {
                background-color: $elevation-04 !important;
            }
        }
    }

    &-row {
        background-color: $color-background-primary !important;
        color: $color-text-light !important;

        border-color: transparent !important;
        box-shadow: inset 0px -1px 0px $color-border;

        &:hover {
            background-color: $elevation-08 !important;
        }

        &-last {
            box-shadow: inset 0 -1px 0 transparent !important;
        }

        &.isCollected {
            background-color: color($color-primary a(0.2)) !important;

            &:hover {
                background-color: color($color-primary a(0.3)) !important;
            }
        }
    }

    &-cell {
        display: flex !important;
        align-items: center;
        height: calc($unit * 10);

        font-size: $type-sml;
        line-height: 20px;

        border: 0 !important;
    }

    &-overlay-loading-wrapper {
        background-color: rgba(64, 65, 67, 0.5) !important;
    }

    &-react-container {
        min-height: calc($unit * 5);

        & div {
            display: flex;

            font-size: $type-sml;
            line-height: 20px;

            & .ag-icon {
                margin-left: calc($unit * 2);
            }
        }
    }
}

.ag-header-cell {
    min-width: 220px !important;
}

.ag-cell {
    min-width: 220px !important;
}

.empty-state {
    & .ag-body-viewport {
        min-height: 470px !important;
    }
}

.ag-body-viewport.ag-layout-normal {
    scrollbar-color: $color-primary $elevation-08;
    scrollbar-width: thin;
}

.ag-body-horizontal-scroll {
    max-height: 8px !important;
    min-height: auto !important;

    &-viewport {
        scrollbar-width: thin !important;

        max-height: 8px !important;
        min-height: 8px !important;
    }

    &-container {
        max-height: 8px !important;
        min-height: 8px !important;
    }
}

.ag-horizontal-right-spacer {
    max-width: 0 !important;
    min-width: 0 !important;
}

.ag-center-cols-container {
    min-width: 100% !important;
}

.ag-cell,
.ag-cell .ag-cell-wrapper .ag-cell-value {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    white-space: normal;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    line-height: 16px !important;
}
