.c-accordion {
    border-radius: $radius-base;

    transition: all 300ms ease-out;

    &--light {
        background-color: $grey-2;
    }

    &--dark {
        background-color: $color-background-primary;
        border: 1px solid $elevation-08;
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: calc($unit * 4);

        font-size: responsive $type-sml $type-base;
        font-range: 320px 1440px;

        line-height: responsive 20px 24px;
        line-height-range: 320px 1440px;
    }

    &__wrapper,
    &__header {
        padding: calc($unit * 4);
    }

    &__wrapper {
        overflow: hidden;
    }

    &__content {
        height: 400px;
        max-height: 400px;
        padding-right: calc($unit * 4);

        overflow: auto;
    }

    &__actions {
        display: flex;
        align-items: center;
        gap: calc($unit * 1);
    }

    &.is-open {
        & .c-accordion__header {
            border-bottom: 1px solid $elevation-08;
        }
    }
}
