/*------------------------------------*\
    # component.c-device-menu
\*------------------------------------*/

.c-device-menu {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 334px;

    gap: calc($unit * 4);

    grid-template-areas:
        "c-device-menu__card--results"
        "c-device-menu__card--primary"
        "c-device-menu__card--latest-items";

    @media (--screen-from-xlrg) {
        grid-template-columns: 1fr 1fr;
        grid-template-areas:
            "c-device-menu__card--primary c-device-menu__card--results"
            "c-device-menu__card--primary c-device-menu__card--latest-items";
    }

    &__card {
        padding: calc($unit * 4);

        border-radius: $radius-base;
        background-color: $color-background-primary;

        &--primary {
            grid-area: c-device-menu__card--primary;

            & div > div {
                scrollbar-width: thin;
            }
        }

        &--results {
            grid-area: c-device-menu__card--results;
            height: 330px;

            & div > div {
                scrollbar-width: thin;
            }
        }

        &--latest-items {
            grid-area: c-device-menu__card--latest-items;
        }

        &--search {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;

            @media (--screen-to-sml) {
                & .u-position--rel {
                    margin-right: 0;
                    width: 100%;

                    & .c-input {
                        max-width: 100%;
                    }
                }
            }

            & .c-input--search--sml {
                @media (--screen-from-sml) {
                    min-width: 170px;
                    max-width: 170px;
                    width: auto;
                }

                @media (--screen-from-med) {
                    min-width: 230px;
                    max-width: 290px;
                }
            }
        }

        &__header {
            display: flex;
            justify-content: space-between;

            min-height: calc($unit * 11);

            padding-bottom: calc($unit * 3);
            margin-bottom: calc($unit * 3);

            border-bottom: 1px solid color($color-text-light a(0.3));

            @media (--screen-from-lrg) {
                padding-bottom: calc($unit * 4);
            }
        }
    }

    &__actions {
        display: flex;
        justify-content: flex-end;
        flex: 1;

        margin-top: calc($unit * 3);

        & .c-btn {
            &:last-child {
                margin-left: calc($unit * 2);
            }
        }

        @media (--screen-from-sml) {
            margin-top: 0;
        }
    }

    &__list {
        display: flex;
        flex-direction: column;

        padding-top: $unit;

        &--results {
            height: 245px;

            overflow: auto;

            @media (--screen-from-med) {
                height: 227px;
            }
        }
    }

    &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;

        padding: calc($unit * 3);

        margin: $unit calc($unit * 2);

        font-size: responsive $type-sml $type-base;
        font-range: 320px 1440px;

        border-radius: $radius-base;
        background-color: $elevation-00;

        cursor: pointer;

        transition: 0.3s all;

        &.fullHeight {
            height: 97%;
        }

        &--secondary {
            margin-right: 0;
            margin-left: 0;
        }

        &:hover {
            background-color: color($elevation-00 a(0.6));
        }

        &:focus,
        &--selected {
            outline: 0;
            border-radius: $radius-base;
            box-shadow: 0px 0px 0px 2px $color-secondary;
        }

        &--dragged {
            background-color: $elevation-02;
            border: 1px solid $elevation-08;
            box-shadow: 0px 0px 0px 2px $color-secondary;
        }

        &__info {
            display: flex;
            align-items: center;

            min-height: 26.6px;
            margin-right: $unit;

            overflow: hidden;
        }

        &__title {
            width: 100%;
            max-width: 450px;

            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            &--secondary {
                color: color($color-text-light a(0.6));
            }
        }

        &__separator {
            display: flex;
            align-items: center;
            width: 100%;
            min-height: 26.6px;
        }
    }

    &__note {
        position: relative;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        min-height: calc($unit * 11);

        padding: calc($unit * 2) calc($unit * 4);
        padding-left: calc($unit * 8);

        font-size: $type-sml;
        line-height: 20px;

        border-radius: $radius-base;
        border: 1px solid $saffron;
        background-color: color($saffron a(0.1));

        &--secondary {
            display: inline-flex;
        }

        &:before {
            content: svg-load("../postcss-svg/exclamation-full-sml.svg", fill: $flax);
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
            left: calc($unit * 2);
            height: calc($unit * 4);
        }

        @media (--screen-to-med) {
            display: none;
        }
    }
}
