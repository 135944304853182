/*------------------------------------*\
    # plugin.toastify
\*------------------------------------*/
.Toastify {
    &__toast {
        position: relative;
        padding: 0;
        padding-left: calc($unit * 10);

        background-color: $elevation-08;
        border-radius: $radius-base;

        font-size: responsive $type-sml $type-base;
        font-range: 320px 1440px;

        line-height: responsive 20px 24px;
        line-height-range: 320px 1440px;

        &:before {
            content: "";
            position: absolute;
            top: 50%;
            left: calc($unit * 2);
            transform: translate(0%, -50%);
        }

        &-container {
            right: 0;
            left: auto;

            width: 75vw;
            max-width: 350px;

            z-index: 10;
        }

        &-body {
            width: 100%;
            height: 100%;

            display: flex;
            align-items: center;

            padding: calc($unit * 3);
            padding-bottom: calc($unit * 4);
            padding-right: calc($unit * 6);

            line-height: calc($unit * 5.5);
            background-color: $elevation-06;
        }

        &--success {
            color: $color-text-light;

            &:before {
                content: svg-load("../postcss-svg/approve.svg", stroke: $color-success) !important;
            }

            /* Progress bar */
            & .Toastify__progress-bar--success {
                background: color($color-success a(100%));
            }
        }

        &--error {
            color: $color-text-light;

            &:before {
                content: svg-load("../postcss-svg/exclamation.svg", stroke: $color-warning) !important;
            }

            /* Progress bar */
            & .Toastify__progress-bar--error {
                background: color($color-warning a(100%));
            }
        }

        &--warning {
            color: $color-text-light;

            &:before {
                content: svg-load("../postcss-svg/info.svg", stroke: $color-note) !important;
            }

            /* Progress bar */
            & .Toastify__progress-bar--warning {
                background: color($color-note a(100%));
            }
        }
    }

    &__close-button {
        position: absolute;
        right: 0;
        top: calc($unit * 2);

        width: calc($unit * 8);
    }

    &__progress-bar {
        opacity: 1 !important;

        &--animated {
            animation: a linear 1 forwards;
        }
    }

    &__bounce-enter--bottom-right {
        animation-name: b;
    }

    &__bounce-exit--bottom-right {
        animation-name: c;
    }
}

@keyframes a {
    100% {
        left: 100%;
        width: 50%;
    }

    50% {
        left: 0;
        width: 100%;
    }
    0% {
        left: 0;
        width: 0;
    }
}

@keyframes b {
    0%,
    60%,
    75%,
    90%,
    100% {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    0% {
        opacity: 0;
        transform: translate3d(3000px, 0, 0);
    }
    60% {
        opacity: 1;
        transform: translate3d(-25px, 0, 0);
    }
    75% {
        transform: translate3d(10px, 0, 0);
    }
    90% {
        transform: translate3d(-5px, 0, 0);
    }
    100% {
        transform: none;
    }
}

@keyframes c {
    0% {
        opacity: 1;
        transform: translate3d(-20px, 0, 0);
    }
    100% {
        opacity: 0;
        transform: translate3d(2000px, 0, 0);
    }
}
