/*------------------------------------*\
    # base.globals
\*------------------------------------*/

/**
 * Initialize typomatic in project (sets font-size and line-height
 * on html selector.
 */
html {
    height: 100%;
    font-size: responsive;
    /* scroll-behavior: smooth; */
    
    scrollbar-color: $color-primary $grey-2;
    scrollbar-width: thin;
}

body {
    min-height: 100%;
    overflow-x: hidden;
    margin: 0;
    /* background: $body-background; */

    font-family: $ff-sans;
    line-height: 1.5;
    color: white;
}

img {
    max-width: 100%;
    vertical-align: middle;
}

svg {
    max-width: 100%;
}
