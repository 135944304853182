.c-floating-card {
    position: fixed;
    bottom: calc($unit * 5);
    left: 50%;

    width: 96%;
    max-width: 500px;

    transform: translateX(-50%);

    padding: calc($unit * 4);

    border-radius: $radius-base;
    background-color: $color-background-primary;
    border: 1px solid $elevation-08;
    color: $color-text-light;

    z-index: 10;
}
