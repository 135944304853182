/*------------------------------------*\
    # component.premium
\*------------------------------------*/

.c-premium {
    &__info {
        width: 90%;

        margin: 0 auto;
        margin-top: calc($unit * 6);
        padding: calc($unit * 4);

        background-color: $elevation-00;
        border: 1px solid $color-primary;
        border-radius: $radius-base;

        @media (--screen-from-sml) {
            width: 85%;
        }

        &--full {
            width: 100%;

            margin: 0;
            /* margin-bottom: calc($unit * 3); */

            @media (--screen-from-sml) {
                display: none;
            }
        }
    }

    &__header {
        display: flex;
        align-items: center;

        margin-bottom: calc($unit * 2);
    }
}
