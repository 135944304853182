/*------------------------------------*\
    # component.c-dashboard
\*------------------------------------*/

.c-dashboard {
    &__count {
        font-size: calc($unit * 8);
        line-height: 1;
        text-align: right;

        &--increase {
            color: $color-success;
        }

        &--decrease {
            color: $charm;
        }

        &--neutral {
            color: color($color-text-light a(0.6));
        }

        &--secondary {
            padding-top: calc($unit * 4);
            margin-bottom: calc($unit * 3);

            text-align: left;

            @media (--screen-from-sml) {
                padding-top: 0;
            }
        }

        &--med {
            font-size: calc($unit * 8);
            line-height: 1;
        }

        &--lrg {
            font-size: 42px;
            line-height: 1;
            margin-bottom: calc($unit * 3);
        }
    }
}
