/*------------------------------------*\
    # component.sidebar
\*------------------------------------*/

.c-sidebar {
    &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;

        margin-bottom: calc($unit * 3);

        &:last-child {
            margin-bottom: 0;
        }
    }
}
