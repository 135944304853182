/*------------------------------------*\
    # plugin.dropdown
\*------------------------------------*/
.react-select {
    &__control {
        min-height: calc($unit * 11) !important;
        padding: 0 calc($unit * 3) !important;
        padding-right: 0 !important;

        font-family: $ff-sans;
        font-size: $type-base;

        background-color: $elevation-02 !important;
        border-color: color($color-text-light a(0.3)) !important;
        border-radius: $radius-base !important;
    }

    &__option {
        color: $color-text-dark !important;
        background: $color-secondary !important;

        cursor: pointer !important;
    }

    &__container {
        border-radius: $radius-base !important;
    }

    &__Input {
        font-family: $ff-sans;
        font-size: $type-base;
    }

    &__placeholder,
    &__single-value {
        font-size: $type-base !important;
        color: color($color-text-light a(0.3)) !important;
    }

    &__value-container {
        padding: 0 !important;
    }

    &__indicator-separator {
        background-color: color($color-text-light a(0.3));
    }

    &__indicator svg {
        fill: color($color-text-light a(0.3));
    }

    &__menu {
        font-size: $type-base !important;

        transition: all 250ms $transition;
    }

    &__menu-list {
        scrollbar-width: thin;
    }

    &__indicator {
        cursor: pointer !important;

        &:hover {
            color: $grey-4 !important;
        }
    }

    &__multi-value {
        align-items: center;

        font-size: responsive $type-sml $type-base;
        font-range: 320px 1440px;

        line-height: responsive 20px 24px;
        line-height-range: 320px 1440px;

        background-color: $color-primary !important;
        border-radius: $radius-full !important;

        &__label {
            padding: 0 $unit !important;
            padding-left: calc($unit * 3) !important;

            color: $white !important;
            font-size: $type-sml !important;
            line-height: 20px !important;
        }

        &__remove {
            display: flex;
            justify-content: center;
            height: 24px;
            width: 24px;

            transition: 0.3s all;
            cursor: pointer;
            border-radius: 50% !important;
            color: $color-text-dark;

            & svg {
                width: 14px;
                height: 14px;

                color: $white;

                border-radius: 50%;
                transition: 0.3s all;
            }

            &:hover {
                background-color: $color-primary !important;
                color: $color-text-dark;

                & svg {
                    color: $color-text-light;
                    background: color($elevation-04 a(0.6));

                    transform: rotate(90deg);
                }
            }
        }
    }
}

.c-dropdown {
    &--dark {
        & .react-select {
            &__menu {
                background: $elevation-08 !important;

                & div {
                    scrollbar-width: thin;
                }
            }

            &__multi-value__label {
                color: $white !important;
            }

            &__value-container {
                padding: 0 !important;
            }

            &__single-value {
                font-size: $type-base !important;
                color: $color-text-light !important;
            }

            &__placeholder {
                font-size: $type-base !important;
                color: color($color-text-light a(0.3)) !important;
            }

            &__control {
                background-color: transparent !important;
                border-color: $color-text-light !important;

                &:hover {
                    border-color: $color-text-light !important;
                }

                &--is-focused {
                    box-shadow: 0 0 0 2px $color-secondary !important;
                }

                &--is-disabled {
                    border-color: $color-border !important;
                }
            }

            &__option {
                color: $color-text-light !important;
                background: $elevation-08 !important;

                &--is-focused {
                    background: $elevation-06 !important;
                }

                &--is-selected {
                    background: $color-secondary !important;
                }
            }

            &__Input,
            &__input {
                color: $color-text-light !important;
            }
        }
    }

    &--sml {
        max-width: 280px;
        min-width: 210px;

        font-size: $type-base;

        z-index: 3;

        @media (--screen-from-sml) {
            min-width: 280px;
        }

        & .react-select {
            &__control {
                min-height: 35px !important;
                max-height: 35px !important;

                font-size: $type-base;
            }

            &__indicator {
                padding: 0 calc($unit * 2);
            }
        }
    }

    &--sml-custom {
        min-width: 360px;
        max-width: 400px;

        font-size: $type-base;

        z-index: 3;

        @media (--screen-from-lrg) {
            min-width: 400px;
        }

        & .react-select {
            &__control {
                min-height: 35px !important;

                font-size: $type-base;
            }

            &__indicator {
                padding: 0 calc($unit * 2);
            }
        }
    }

    &--base {
        & .react-select {
            &__control {
                min-height: 43px !important;
            }

            &__indicator {
                padding: 0 calc($unit * 2);
            }

            &__placeholder {
                font-size: $type-sml;
                line-height: 20px;
            }
        }
    }
}
