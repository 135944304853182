/*------------------------------------*\
    # component.c-card
\*------------------------------------*/

.c-card {
    &--tny {
        padding: calc($unit * 2);
    }

    &--sml {
        padding: calc($unit * 3);
    }

    &--med {
        padding: calc($unit * 4);
    }

    &--lrg {
        padding: calc($unit * 6);
    }

    &--primary {
        position: relative;
        padding: calc($unit * 4);

        border-radius: $radius-base;
        background-color: $color-background-primary;
    }

    &--table {
        position: relative;

        border-radius: $radius-base;
        border: 1px solid color($color-border a(0.5));
        overflow: hidden;
    }

    &--secondary {
        background-color: $elevation-00;
        border-radius: $radius-base;
    }

    &--outline {
        border: 1px solid $white;
        border-radius: $radius-base;
    }

    &--square {
        border-radius: 0;
    }

    &--sidebar {
        &--sml {
            height: 180px;
        }

        &--base {
            max-height: 218px;
        }
    }

    &--message {
        position: relative;
        display: flex;
        align-items: center;

        padding: calc($unit * 4);
        padding-left: calc($unit * 20);
        margin: calc($unit * 2) 0;

        border-radius: $radius-base;
        font-size: responsive $type-sml $type-base;
        font-range: 320px 1440px;

        line-height: responsive 20px 24px;
        line-height-range: 320px 1440px;
        border: 1px solid;

        @media (--screen-from-sml) {
            margin: 0;
        }

        &:before {
            content: "";
            position: absolute;
            left: calc($unit * 9);
            top: 50%;
            transform: translate(-50%, -50%);
            width: calc($unit * 9);
            height: calc($unit * 9);
            border-radius: 50%;
        }

        &--info {
            color: $color-text-light;
            background-color: color($color-primary a(0.2));
            border-color: $color-primary;

            &:before {
                background-image: svg-load("../postcss-svg/question.svg", fill: $color-primary);
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
            }
        }

        &--cc-sync {
            color: $color-text-light;
            background-color: color($color-primary a(0.2));
            border-color: $color-primary;

            &:before {
                background-image: svg-load("../postcss-svg/credit-cards/cc-sync.svg" fill: $color-primary);
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
                border-radius: 0;
            }
        }

        &--password {
            color: $color-text-light;
            background-color: color($color-secondary a(0.2));
            border-color: $color-secondary;
            padding: calc($unit * 4);

            &:before {
                display: none;
            }
        }
    }

    &--list-item {
        display: flex;
        align-items: center;
        justify-content: space-between;

        padding: calc($unit * 3) calc($unit * 4);

        margin-right: calc($unit * 2);
        margin-left: calc($unit / 2);
        margin-bottom: calc($unit * 2);

        font-size: responsive $type-sml $type-base;
        font-range: 320px 1440px;

        border-radius: $radius-base;
        background-color: $color-background-primary;

        transition: 0.3s all;
    }

    &--content-agreement {
        overflow-y: auto;
        max-height: 300px;

        &__list {
            margin: calc($unit * 3);
            padding: calc($unit * 3);
            list-style: decimal;
        }
    }
}
