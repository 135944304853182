.c-stepper {
    &__wrapper {
        display: flex;
        justify-content: space-between;

        padding: calc($unit * 3);
        margin-bottom: calc($unit * 5);

        background-color: $color-background-primary;
        border-radius: $radius-base;

        @media (--screen-to-sml) {
            flex-direction: column;
        }
    }

    &__step {
        display: flex;
        align-items: center;
        gap: calc($unit * 3);

        padding-right: calc($unit * 3);

        @media (--screen-to-sml) {
            flex-direction: column;
        }

        &:not(:last-child) {
            flex: 1;
        }
    }

    &__separator {
        display: flex;

        @media (--screen-to-sml) {
            border-right: 1px dashed $elevation-10;
            height: calc($unit * 10);
        }

        @media (--screen-from-sml) {
            flex: 1;
            border-bottom: 1px dashed $elevation-10;
        }
    }

    &__title {
        padding: $unit calc($unit * 3);

        color: $elevation-10;
        font-weight: $type-weight-bold;

        border-radius: $unit;

        &:nth-child(2) {
            text-align: center;
        }

        &:last-child {
            text-align: right;
        }

        &.active {
            background-color: $elevation-00;
            color: $color-primary;
        }
    }
}
