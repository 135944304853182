.c-progressbar {
    width: 100%;

    &__wrapper {
        position: relative;
        width: 100%;
        height: calc($unit * 2);

        display: flex;
        flex: 1;

        background-color: $elevation-10;
        border-radius: $radius-full;

        overflow: hidden;

        & .c-progressbar__value {
            display: flex;
            justify-content: flex-end;

            width: 100%;
        }
    }

    &__range {
        position: absolute;
        height: 100%;
        left: 0;

        &.in-progress {
            background-color: $color-primary;
        }

        &.is-finished {
            background-color: $color-success;
        }

        &.is-failed {
            background-color: $color-warning;
        }
    }

    &__value {
        max-width: 483px;

        font-size: $type-sml;
        line-height: 20px;
        font-weight: $type-weight-bold;

        color: $white;

        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        white-space: normal;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
}
