/*------------------------------------*\
    # component.nav
\*------------------------------------*/

.c-nav {
    &__wrapper {
        display: none;

        @media (--screen-from-lrg) {
            display: flex;
            flex-direction: column;
            padding-bottom: calc($unit * 8);
        }
    }

    &__version {
        position: absolute;
        bottom: $unit;

        width: 100%;

        padding-left: $unit;

        color: color($white a(0.5));
        font-size: $type-sml;
        line-height: 20px;
    }

    &__btn {
        position: fixed;
        right: calc($unit * 5);
        top: calc($unit * 5);

        display: block;

        width: 35px;
        height: 35px;

        padding: calc($unit * 2);

        box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
        border: 1px solid color($elevation-08 a(0.9));
        border-radius: 3px;

        cursor: pointer;

        transition: all 250ms ease-out;
        z-index: 34;

        &--secondary {
            background-color: $elevation-08;
        }

        &:hover {
            background-color: color($elevation-08 a(0.9));
        }

        &::before {
            content: "";
            position: absolute;
            top: 10px;
            left: 25%;

            width: calc($unit * 3);
            height: calc($unit / 2);

            background-color: $color-text-light;

            transform-origin: center;
            transition: all 250ms cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.2s,
                transform 0.13s cubic-bezier(0.55, 0.06, 0.68, 0.19);
        }

        &::after {
            content: "";
            position: absolute;
            top: 20px;
            left: 25%;

            width: calc($unit * 4);
            height: calc($unit / 2);

            background-color: $color-text-light;

            transform-origin: center;
            transition: all 250ms cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.2s,
                transform 0.13s cubic-bezier(0.55, 0.06, 0.68, 0.19);
        }

        @media (--screen-from-sml) {
            bottom: 70px;
        }

        @media (--screen-from-lrg) {
            display: none;
        }
    }

    &__link {
        display: flex;
        align-items: center;
        padding: calc($unit * 3) calc($unit * 6);

        color: $white;

        font-size: responsive $type-sml $type-base;
        font-range: 320px 1440px;

        line-height: responsive 20px 20px;
        line-height-range: 320px 1440px;

        transition: all 250ms ease-out;

        &__content {
            display: flex;
            align-items: center;
            word-break: break-word;
        }

        &--expanded {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &.is-active,
        &:hover,
        &:focus {
            color: $color-primary;

            & .c-nav__icon {
                &--dashboard {
                    background-image: svg-load("../postcss-svg/navigation/dashboard.svg", stroke: $color-primary);
                }

                &--stations {
                    background-image: svg-load("../postcss-svg/navigation/stations.svg", stroke: $color-primary);
                }

                &--podcasts {
                    background-image: svg-load("../postcss-svg/navigation/podcasts.svg", stroke: $color-primary);
                }

                &--users {
                    background-image: svg-load("../postcss-svg/navigation/users.svg", stroke: $color-primary);
                }

                &--shuffles {
                    background-image: svg-load("../postcss-svg/navigation/shuffles.svg", stroke: $color-primary);
                }

                &--device-menu {
                    background-image: svg-load("../postcss-svg/navigation/device-menu.svg", stroke: $color-primary);
                }

                &--producers {
                    background-image: svg-load("../postcss-svg/navigation/producers.svg", stroke: $color-primary);
                }

                &--playlists {
                    background-image: svg-load("../postcss-svg/navigation/playlists.svg", stroke: $color-primary);
                }

                &--logout {
                    background-image: svg-load("../postcss-svg/navigation/logout.svg", stroke: $color-primary);
                }

                &--devices {
                    background-image: svg-load("../postcss-svg/navigation/devices.svg", stroke: $color-primary);
                }

                &--settings {
                    background-image: svg-load("../postcss-svg/navigation/settings.svg", stroke: $color-primary);
                }

                &--analytics {
                    background-image: svg-load("../postcss-svg/navigation/analytics.svg", stroke: $color-primary);
                }

                &--home {
                    background-image: svg-load("../postcss-svg/navigation/home.svg", stroke: $color-primary);
                }

                &--nigun {
                    background-image: svg-load("../postcss-svg/navigation/nigun.svg", stroke: $color-primary);
                }

                &--subscriptions {
                    background-image: svg-load("../postcss-svg/navigation/subscriptions.svg", stroke: $color-primary);
                }

                &--orders {
                    background-image: svg-load("../postcss-svg/navigation/orders.svg", stroke: $color-primary);
                }

                &--mobile {
                    background-image: svg-load("../postcss-svg/devices/mobile-sml.svg", stroke: $color-primary);
                }

                &--content-explorer {
                    background-image: svg-load("../postcss-svg/navigation/content-explorer.svg", fill: $color-primary);
                }
            }
        }

        &:focus {
            box-shadow: none;
            background-color: $elevation-08;
        }

        &:hover {
            background-color: $elevation-08;
        }
    }

    &__icon {
        display: inline-block;

        min-width: calc($unit * 6);
        height: calc($unit * 6);

        margin-right: calc($unit * 3);

        background-repeat: no-repeat;
        background-position: center center;
        background-size: calc($unit * 5);
        vertical-align: middle;

        transition: all 250ms ease-out;

        &--dashboard {
            background-image: svg-load("../postcss-svg/navigation/dashboard.svg", stroke: $white);
        }

        &--stations {
            background-image: svg-load("../postcss-svg/navigation/stations.svg", stroke: $white);
        }

        &--podcasts {
            background-image: svg-load("../postcss-svg/navigation/podcasts.svg", stroke: $white);
        }

        &--users {
            background-image: svg-load("../postcss-svg/navigation/users.svg", stroke: $white);
        }

        &--shuffles {
            background-image: svg-load("../postcss-svg/navigation/shuffles.svg", stroke: $white);
        }

        &--device-menu {
            background-image: svg-load("../postcss-svg/navigation/device-menu.svg", stroke: $white);
        }

        &--producers {
            background-image: svg-load("../postcss-svg/navigation/producers.svg", stroke: $white);
        }

        &--playlists {
            background-image: svg-load("../postcss-svg/navigation/playlists.svg", stroke: $white);
        }

        &--logout {
            background-image: svg-load("../postcss-svg/navigation/logout.svg", stroke: $white);
        }

        &--devices {
            background-image: svg-load("../postcss-svg/navigation/devices.svg", stroke: $white);
        }

        &--settings {
            background-image: svg-load("../postcss-svg/navigation/settings.svg", stroke: $white);
        }

        &--analytics {
            background-image: svg-load("../postcss-svg/navigation/analytics.svg", stroke: $white);
        }

        &--home {
            background-image: svg-load("../postcss-svg/navigation/home.svg", stroke: $white);
        }

        &--nigun {
            background-image: svg-load("../postcss-svg/navigation/nigun.svg", stroke: $white);
        }

        &--subscriptions {
            background-image: svg-load("../postcss-svg/navigation/subscriptions.svg", stroke: $white);
        }

        &--orders {
            background-image: svg-load("../postcss-svg/navigation/orders.svg", stroke: $white);
        }

        &--mobile {
            background-image: svg-load("../postcss-svg/devices/mobile-sml.svg", stroke: $white);
        }

        &--content-explorer {
            background-image: svg-load("../postcss-svg/navigation/content-explorer.svg", fill: $white);
        }
    }

    &__menu {
        &__link {
            font-size: $type-sml;
            padding-left: 60px;
        }
    }
}

@keyframes linkHoverOut {
    0% {
        width: 100%;
    }
    100% {
        width: 0;
        right: 0;
    }
}
