/*------------------------------------*\
    # component.section
\*------------------------------------*/

.section {
    &--primary {
        padding: calc($unit * 20) calc($unit * 8);
    }

    &--contact {
        background-color: $white;

        &__copy {
            color: #1E2633;
        }

        &__body {
            width: 60%;
        }
    }

    &--blog {
        display: flex;
    }

    &__title {
        margin: calc($unit * 2);
        font-size: $type-tny;
        font-weight: $type-weight-regular;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 0.18em;

        @media (--screen-from-med) {
            margin: calc($unit * 6);
        }
    }

    &--footer {
        background-color: #1E2633;
    }
}
