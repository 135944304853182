/*------------------------------------*\
    # utils.positions
\*------------------------------------*/

.u {
	&-position {
		&--stc {
			position: static;
		}

		&--stk {
			position: sticky;
		}

		&--rel {
			position: relative;
		}

		&--fix {
			position: fixed;
		}
		
		&--abs {
			position: absolute;
		}
	}
}