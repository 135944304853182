/*------------------------------------*\
    # component.c-empty-state
\*------------------------------------*/

.c-empty-state {
    &--table,
    &--dashboard,
    &--404,
    &--shuffle {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-height: 400px;
        text-align: center;
    }

    &--dashboard {
        height: 100vh;
        margin-left: 0;

        @media (--screen-from-lrg) {
            margin-left: 200px;
        }

        &--user {
            margin-left: 0;
        }
    }

    &__title,
    &__desc {
        color: $color-text-light;
    }

    &__title {
        margin-top: calc($unit * 4);
    }

    &__desc {
        max-width: 350px;
        margin: 0 auto;
        margin-bottom: calc($unit * 4);

        text-align: center;

        &--secondary {
            margin: 0 auto;
            margin-bottom: calc($unit * 4);

            text-align: center;
        }
    }

    &--no-results {
        display: flex;
        flex-direction: column;
        align-items: center;

        width: 100%;
        height: 245px;

        /* padding-top: calc($unit * 4); */

        color: $color-text-light;
        text-align: center;

        @media (--screen-from-med) {
            height: 227px;
        }

        &--sml {
            height: auto;
        }

        &--lrg {
            justify-content: center;

            height: 500px;
        }

        &--absolute {
            position: absolute;
            width: 100%;
            left: 0;
            top: 25%;

            & .u-icon {
                min-height: 200px;
            }

            @media (--screen-from-lrg) {
                left: 100px;
            }
        }
    }

    &--home {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        @media (--screen-from-lrg) {
            padding-left: 200px;
        }
    }

    &--center {
        justify-content: center;
    }
}
