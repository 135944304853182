/*------------------------------------*\
    # component.home
\*------------------------------------*/

$imgMed: 180px;
$imgLrg: 200px;

.c-home {
    &__filter {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;

        margin-bottom: calc($unit * 4);
        margin-top: $unit;

        &__wrapper {
            display: flex;
            justify-content: space-between;
            flex: 1;
        }

        &__actions {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            flex: 1;

            @media (--screen-from-sml) {
                justify-content: space-between;
            }

            &--end {
                width: 100%;
                justify-content: flex-end;
            }

            & div {
                &:first-child {
                    width: 100%;

                    @media (--screen-from-sml) {
                        width: auto;
                    }
                }
            }
        }

        &__select {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            /* min-width: 200px; */

            margin-right: calc($unit * 3);
        }

        &__label {
            margin-right: calc($unit * 2);

            font-size: responsive $type-sml $type-base;
            font-range: 320px 1440px;

            line-height: responsive 20px 24px;
            line-height-range: 320px 1440px;

            font-weight: $type-weight-bold;
            color: $color-text-light;
        }

        & .c-select__input {
            color: rgba(236, 236, 236, 0.6);
        }

        @media (--screen-to-sml) {
            &__actions {
                flex-wrap: wrap;
                width: 100%;
            }

            & .c-select__dropdown {
                top: calc($unit * 6);
                right: 0;
            }
        }

        @media (--screen-to-med) {
            & .c-dropdown--sml {
                min-width: 100%;
                margin-left: 0;
                margin-top: calc($unit * 4);
            }
        }
    }

    &__bulk {
        position: absolute;
        top: 0;
        left: calc($unit * 2.5);

        z-index: 2;
    }

    &__header {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        gap: calc($unit * 3);

        width: 100%;
        min-height: calc($unit * 11);

        @media (--screen-from-med) {
            flex-wrap: no-wrap;
            margin-right: calc($unit * 10.5);

            & .c-premium__info {
                order: 3;
            }
        }

        @media (--screen-from-lrg) {
            margin-right: 0px;
        }

        &__input {
            &__wrapper {
                display: flex;
                align-items: center;
                justify-content: space-between;

                width: 100%;
                margin-right: 50px;

                @media (--screen-from-sml) {
                    flex: 1;
                    margin-right: 0;
                }
            }
        }

        &__label {
            position: absolute;

            font-size: calc($type-tny + 1px);
            line-height: 20px;

            color: color($color-text-light a(0.6));
        }

        &__actions {
            width: 100%;

            padding: calc($unit * 9) 0 calc($unit * 3);

            border-bottom: 1px solid color($color-text-light a(0.2));

            &--secondary {
                padding: calc($unit * 6) 0 calc($unit * 3);
            }

            @media (--screen-from-med) {
                justify-content: flex-end;
                width: auto;

                padding: 0;

                border-bottom: 0;
            }
        }

        &__options {
            display: flex;
            justify-content: flex-end;

            margin-bottom: calc($unit * 4);

            &--secondary {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;

                margin-bottom: calc($unit * 4);
            }
        }
    }

    &__list {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(min(95px, 100%), 1fr));
        grid-gap: calc($unit * 3);

        @media (--screen-from-sml) {
            grid-template-columns: repeat(auto-fill, minmax(min(140px, 100%), 1fr));
            grid-gap: calc($unit * 4);
        }

        &--search {
            display: flex;
            flex-wrap: wrap;

            padding: calc($unit * 3);

            border-radius: $radius-base;
            background-color: $color-background-primary;
        }

        &--scroll {
            display: flex;

            padding: $unit;
            padding-top: calc($unit * 1.5);
            padding-left: 0;
            margin-bottom: calc($unit * 4);

            overflow: scroll;
            scrollbar-width: none;

            &::-webkit-scrollbar {
                display: none;
            }

            & .c-home__card {
                margin-right: calc($unit * 3);
            }
        }

        &__header {
            display: flex;
            align-items: center;
            justify-content: space-between;

            & .u-icon {
                cursor: pointer;
            }
        }
    }

    &__card {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;

        min-width: 103px;
        max-width: 103px;
        min-height: 135px;

        padding: $unit;
        padding-top: 0;

        font-size: $type-sml;
        line-height: 20px;

        border: 1px solid transparent;
        border-radius: $radius-base;

        /* transition: 0.3s all; */
        cursor: pointer;

        @media (--screen-from-sml) {
            min-width: 146px;
            max-width: 146px;
            min-height: 170px;
        }

        &--secondary {
            border: 1px solid color($color-border-primary a(0.5));
            background-color: $color-background-primary;

            & .c-home__card__img-wrapper {
                &:hover {
                    &.status {
                        &--disabled {
                            &:before {
                                display: none;
                            }
                        }

                        &--enabled {
                            &:before {
                                display: none;
                            }
                        }
                    }
                }
            }

            & .c-home__card__title {
                &:hover {
                    color: color($color-primary a(1));
                    text-decoration: none;
                }
            }

            &.c-card__torah {
                border: none;
                cursor: inherit;

                & .c-home__card__media {
                    cursor: pointer;
                }

                & .c-home__status {
                    top: $unit;
                    right: $unit;
                }

                & .c-home__card__title:hover {
                    color: $color-text-light;
                }
            }
        }

        &--tertiary {
            border: 1px solid color($color-border-primary a(0.5));
            background-color: $color-background-primary;
            opacity: 0.3;

            & .c-home__card__title {
                &:hover {
                    color: color($color-primary a(1));
                    text-decoration: none;
                }
            }
        }

        &__wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;

            margin: 0 auto;

            text-align: center;

            @media (--screen-from-xlrg) {
                flex-direction: row;
                align-items: flex-end;

                margin: 0;

                text-align: left;
            }
        }

        &__media {
            position: relative;

            min-width: 100px;
            height: auto;

            border-radius: $radius-base;
            background-color: $color-background-primary;

            transition: all 0.3s ease-out;

            & img {
                min-width: 100px;
                max-width: 100px;
                height: 100px;

                object-fit: cover;
            }

            @media (--screen-from-sml) {
                min-width: 130px;
                max-width: 140px;
                min-height: 130px;
                max-height: 140px;

                & img {
                    min-width: 130px;
                    max-width: 140px;
                    min-height: 130px;
                    max-height: 140px;
                }
            }

            &--lrg {
                margin-bottom: calc($unit * 2);

                & img {
                    min-width: 100px;
                    max-width: 100px;
                    height: 100px;

                    object-fit: cover;
                }

                @media (--screen-from-sml) {
                    min-width: $imgMed;
                    max-width: $imgMed;
                    min-height: $imgMed;
                    max-height: $imgMed;

                    & img {
                        min-width: $imgMed;
                        max-width: $imgMed;
                        min-height: $imgMed;
                        max-height: $imgMed;
                    }
                }

                & .c-home__card__img-wrapper {
                    @media (--screen-from-sml) {
                        min-width: $imgMed;
                        max-width: $imgMed;
                        min-height: $imgMed;
                        max-height: $imgMed;
                    }
                }

                @media (--screen-from-xlrg) {
                    margin-right: calc($unit * 6);

                    min-width: $imgLrg;
                    max-width: $imgLrg;
                    min-height: $imgLrg;
                    max-height: $imgLrg;

                    & img {
                        min-width: $imgLrg;
                        max-width: $imgLrg;
                        min-height: $imgLrg;
                        max-height: $imgLrg;
                    }
                }

                & .c-home__card__img-wrapper {
                    @media (--screen-from-xlrg) {
                        min-width: $imgLrg;
                        max-width: $imgLrg;
                        min-height: $imgLrg;
                        max-height: $imgLrg;
                    }
                }
            }
        }

        &__description {
            max-width: 500px;

            text-align: left;

            font-size: responsive $type-sml $type-base;
            font-range: 320px 1440px;

            line-height: responsive 20px 24px;
            line-height-range: 320px 1440px;
        }

        &__img-wrapper {
            position: relative;
            overflow: hidden;

            border: 1px solid color($color-text-light a(0.35));

            border-radius: $radius-base;
            background-color: $elevation-00;

            &:before {
                opacity: 0;
                z-index: 1;

                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;

                /* border-radius: $radius-base; */
                background-color: color($elevation-00 a(0.6));

                background-position: center;
                background-repeat: no-repeat;
                transition: all 0.3s ease-out;
            }

            &:hover {
                &.status {
                    &--disabled {
                        &:before {
                            opacity: 1;

                            background-image: svg-load("../postcss-svg/power.svg", fill: $charm);
                        }
                    }

                    &--enabled {
                        &:before {
                            opacity: 1;

                            background-image: svg-load("../postcss-svg/power.svg", fill: $color-success);
                        }
                    }

                    &--off {
                        cursor: default;
                    }
                }
            }
        }

        &__title {
            display: flex;
            align-items: center;

            width: 100%;
            max-width: 110px;
            height: 100%;

            text-align: left;

            color: $color-text-light;
            border-bottom: 1px solid color($color-primary a(0));

            transition: 250ms all;

            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            @media (--screen-from-sml) {
                height: auto;
                min-width: 130px;

                margin: calc($unit * 1) 0;
            }

            &:hover {
                color: color($color-primary a(0.8));

                text-decoration: underline;
            }

            &--lrg {
                max-width: 300px;

                font-size: $type-med;
                line-height: 28px;

                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                @media (--screen-from-sml) {
                    font-size: responsive $type-lrg $type-xlrg;
                    font-range: 320px 1440px;

                    line-height: responsive 40px 44px;
                    line-height-range: 320px 1440px;
                }

                @media (--screen-from-xxlrg) {
                    max-width: 500px;
                }
            }
        }

        &--stations {
            align-items: center;

            & .c-home__card__title {
                text-align: center;
            }

            & .c-home__card__media {
                & img {
                    border-radius: 50%;
                }
            }

            & .c-home__card__img-wrapper {
                border-radius: 50%;

                &::before {
                    border-radius: 50%;
                }

                & img {
                    border-radius: 50%;
                }
            }
        }

        &--premium {
            position: absolute;
            left: calc(-$unit * 2);
            bottom: 13px;

            display: flex;
            align-items: center;
            gap: $unit;

            padding: calc($unit / 2) calc($unit * 2);
            padding-left: calc($unit * 3);

            font-size: $type-sml;
            line-height: 20px;

            background-color: $elevation-00;
            border: 1px solid $color-background-primary;
            border-radius: 0 $radius-full $radius-full 0;

            @media (--screen-from-sml) {
                left: 0;
            }

            &--lrg {
                @media (--screen-from-sml) {
                    padding: $unit;
                    padding-right: calc($unit * 6);
                    padding-left: calc($unit * 4);

                    font-size: responsive $type-sml $type-base;
                    font-range: 320px 1440px;

                    line-height: responsive 20px 24px;
                    line-height-range: 320px 1440px;
                }
            }

            &--secondary {
                bottom: auto;
                left: 0;
                top: 80px;
                padding-left: $unit;
            }
        }
    }

    &__status {
        content: "";
        position: absolute;

        top: -$unit;
        right: -$unit;

        width: calc($unit * 4);
        height: calc($unit * 4);

        border-radius: $radius-full;
        border: 2px solid $color-background-primary;

        transition: all 0.3s ease-out;
        z-index: 2;

        &--enabled {
            background-color: $color-success;
        }

        &--disabled {
            background-color: $charm;
        }

        &--mix {
            background-color: $color-note;
        }

        &--secondary {
            top: calc(-$unit * 2 + 1px);
            right: calc(-$unit * 2);

            width: calc($unit * 6.5);
            height: calc($unit * 6.5);

            &.c-home__status {
                &:before {
                    content: "";
                    position: absolute;

                    width: calc($unit * 5.5);
                    height: calc($unit * 5.5);
                    background-size: calc($unit * 5.5);
                }

                &--enabled {
                    background-color: $color-text-dark;

                    &:before {
                        background-image: svg-load("../postcss-svg/checked.svg", fill: $color-success);
                    }
                }

                &--disabled {
                    background-color: $color-text-dark;

                    &:before {
                        background-image: svg-load("../postcss-svg/checked.svg", fill: $elevation-10);
                    }
                }

                &--mix {
                    background-color: $color-text-dark;

                    &:before {
                        background-image: svg-load("../postcss-svg/checked.svg", fill: $color-note);
                    }
                }
            }
        }
    }

    &__preview {
        padding: calc($unit * 4);

        border-radius: $radius-base;
        background-color: $color-background-primary;

        &__header {
            &__icon {
                position: relative;

                width: 130px;
                height: 130px;

                border-radius: $radius-full;
                border: 1px solid color($color-text-light a(0.35));

                overflow: hidden;

                & .c-home__card--premium {
                    left: $unit;
                }
            }
        }

        &__item {
            display: flex;
            justify-content: space-between;
            align-items: center;

            padding: calc($unit * 3) calc($unit * 2);
            margin-bottom: calc($unit * 3);

            border-radius: $radius-base;

            transition: 0.3s all;

            &:hover {
                background-color: $elevation-08;
            }

            &:last-child {
                margin-bottom: 0;
            }

            &--search {
                display: flex;
                justify-content: flex-start;
                width: 100%;

                padding: calc($unit * 3) calc($unit * 2);
                margin-bottom: 0;

                cursor: pointer;

                @media (--screen-from-sml) {
                    width: 50%;
                }
            }
        }

        &__icon {
            min-width: 50px;
            width: 50px;
            height: 50px;

            border: 1px solid $color-border;
            border-radius: $radius-base;

            background-color: $elevation-00;

            & img {
                object-fit: cover;
                border-radius: $radius-base;
            }
        }

        &__title {
            display: flex;
            align-items: center;
            margin-bottom: $unit;

            font-size: responsive $type-sml $type-base;
            font-range: 320px 1440px;

            line-height: responsive 20px 24px;
            line-height-range: 320px 1440px;

            color: $color-text-light;

            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            white-space: normal;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;

            &--link {
                color: $color-primary;
                text-decoration: underline;
            }
        }

        &__desc {
            max-width: 150px;

            font-size: $type-sml;
            line-height: 16px;

            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            color: color($color-text-light a(0.6));

            @media (--screen-from-sml) {
                max-width: 400px;
            }

            @media (--screen-from-xxlrg) {
                max-width: 600px;
            }

            &--primary {
                color: $color-primary;
            }
        }

        &__sidebar {
            padding: calc($unit * 4);

            border-radius: $radius-base;
            background-color: $color-background-primary;

            word-break: break-all;
        }
    }
}
