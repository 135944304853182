/*------------------------------------*\
    # component.upload
\*------------------------------------*/
$upload-sml: 200px;
$upload-lrg: 360px;

.c-upload {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    max-height: 440px;

    padding: calc($unit * 4);

    background-color: $color-background-primary;
    border-radius: $radius-base;

    &--tny {
        min-height: 300px;
        max-height: 300px;
        padding: calc($unit * 6);

        & img {
            min-height: 252px;
            max-height: 252px;
        }
    }

    &--base {
        min-height: 450px;
        max-height: 450px;
        padding: calc($unit * 6);

        & img {
            min-height: 402px;
            max-height: 402px;
        }
    }

    &__preview {
        min-width: $upload-sml;
        max-width: $upload-sml;
        min-height: $upload-sml;
        max-height: $upload-sml;

        object-fit: cover;

        border: 1px solid color($color-text-light a(0.35));
        border-radius: $radius-base;
    }

    & section {
        width: 100%;
    }

    & div {
        &:focus {
            outline: 0;
            border-radius: $radius-base;
            box-shadow: 0px 0px 0px 2px $color-secondary;
        }
    }

    &__uploaded-img {
        width: 150px;
        height: 150px;

        border-radius: $radius-base;
        border: 1px solid color($color-text-light a(0.25));
    }

    &__wrapper {
        display: grid;
        grid-gap: calc($unit * 6);

        &--sml {
            grid-template-columns: 1fr;

            @media (--screen-from-sml) {
                grid-template-columns: $upload-sml 1fr;
            }
        }

        &--lrg {
            grid-template-columns: 1fr;

            @media (--screen-from-sml) {
                grid-template-columns: $upload-lrg 1fr;
            }
        }
    }

    &__upload {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        margin: 0 auto;
        margin-bottom: calc($unit * 3);

        border-radius: $radius-base;

        background-color: $elevetion-01;
        cursor: pointer;

        & img {
            border: 1px solid color($color-text-light a(0.25));
            border-radius: $radius-base;

            object-fit: cover;
        }

        &--sml {
            max-width: $upload-sml;
            width: 100%;
            height: $upload-sml;
            padding: calc($unit * 4);

            & img {
                width: $upload-sml;
                height: $upload-sml;
            }
        }

        &--lrg {
            max-width: $upload-lrg;
            width: 100%;
            height: $upload-lrg;

            @media (--screen-from-sml) {
                max-width: $upload-lrg;
            }

            & img {
                width: $upload-lrg;
                height: $upload-lrg;
            }
        }

        &--full {
            width: 100%;
            height: $upload-lrg;

            padding: calc($unit * 4);

            & img {
                width: 100%;
                height: 100%;
            }
        }
    }

    &__footer {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    &--sml {
        width: $upload-sml;
        margin: 0 auto;

        & .c-btn {
            max-width: $upload-sml;
        }

        & .c-upload__footer {
            max-width: $upload-sml;
        }

        &--left {
            @media (--screen-from-sml) {
                margin-left: 0;
            }
        }
    }

    &--secondary {
        padding: 0 calc($unit * 4);

        @media (--screen-to-med) {
            margin-bottom: calc($unit * 4);
        }
    }

    &--lrg {
        & .c-btn {
            width: 100%;
            max-width: $upload-lrg;
        }

        & .c-upload__footer {
            max-width: $upload-lrg;
        }
    }

    &--order-3 {
        @media (--screen-to-med) {
            order: 3;
            margin: calc($unit * 3) 0 calc($unit * 4);
        }
    }
}
