.u-gap {
    &--tny {
        gap: calc($unit * 1);
    }

    &--sml {
        gap: calc($unit * 2);
    }

    &--base {
        gap: calc($unit * 3);
    }

    &--med {
        gap: calc($unit * 4);
    }

    &--lrg {
        gap: calc($unit * 5);
    }
}
