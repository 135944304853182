/*------------------------------------*\
    # component.payment
\*------------------------------------*/

.c-payment {
    &__card {
        max-width: 500px;

        padding: calc($unit * 4);

        border-radius: $radius-base;
        background-color: $color-background-primary;

        @media (--screen-to-med) {
            width: 100%;
        }

        @media (--screen-from-med) {
            flex: 1;
            min-width: 340px;
            max-width: 500px;
        }

        &--sml {
            width: 100%;

            @media (--screen-from-sml) {
                min-width: 340px;
                max-width: 340px;
            }
        }

        &--lrg {
            @media (--screen-from-med) {
                max-width: 50%;
            }
        }

        &--full {
            max-width: 100%;
        }

        &--primary {
            border: 1px solid $color-primary;
        }

        &--secondary {
            border: 1px solid $wild-willow;
        }

        &--tertiary {
            border: 1px solid $color-border-primary;
        }

        &--plan {
            display: flex;
            align-items: center;
            gap: calc($unit * 8);

            flex: 1;
            min-width: auto;
            max-width: auto;
        }

        &--info {
            padding: calc($unit * 3);
            margin: calc($unit * 4) 0;

            background-color: $color-text-dark;
            border: 1px solid $color-tertiary;
            border-radius: $radius-base;

            @media (--screen-from-lrg) {
                margin: calc($unit * 8) 0;
            }
        }
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        margin-bottom: calc($unit * 4);

        &--secondary {
            align-items: flex-start;
        }
    }

    &__body {
        display: flex;
        justify-content: center;

        margin-bottom: calc($unit * 5);

        & img {
            width: 100%;

            border-radius: $radius-base;
            object-fit: cover;
        }

        &__item {
            display: flex;
            align-items: center;
            margin-bottom: calc($unit * 3);

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__footer {
        padding: calc($unit * 4) 0;
        margin-bottom: calc($unit * 4);

        border-bottom: 1px solid $color-border;
        border-top: 1px solid $color-border;

        &--base {
            @media (--screen-from-med) {
                min-height: calc($unit * 46);
            }

            @media (--screen-from-xlrg) {
                min-height: calc($unit * 53);
            }
        }
    }

    &__label {
        font-size: $type-sml;
        line-height: 20px;
    }

    &__title {
        font-size: responsive $type-med $type-lrg;
        font-range: 320px 1440px;

        line-height: responsive 32px 40px;
        line-height-range: 320px 1440px;

        &--secondary {
            color: $color-primary;
            font-size: responsive $type-base $type-lrg;
        }
    }

    &__payment-card {
        display: flex;
        flex-direction: column;
    }

    &__subscription {
        &__item {
            display: flex;
            flex-direction: column;

            font-size: responsive $type-sml $type-base;
            font-range: 320px 1440px;

            line-height: responsive 20px 24px;
            line-height-range: 320px 1440px;
        }

        &__status {
            position: relative;
            padding-left: calc($unit * 5);

            &:before {
                content: "";
                position: absolute;
                top: 50%;
                left: 0;

                transform: translateY(-50%);

                width: calc($unit * 3);
                height: calc($unit * 3);

                border: 2px solid $elevation-00;
                border-radius: $radius-full;
            }

            &--active {
                &:before {
                    background-color: $color-success;
                }
            }

            &--cancelled {
                &:before {
                    background-color: $charm;
                }
            }

            &--pastdue {
                &:before {
                    background-color: $color-tertiary;
                }
            }

            &--trial {
                &:before {
                    background-color: $color-secondary;
                }
            }
        }
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        gap: calc($unit * 5);

        max-width: 1440px;

        &--plan {
            flex-wrap: nowrap;
            flex-direction: column;

            & .u-icon--arrow-right--secondary {
                transform: rotate(90deg);
            }

            @media (--screen-from-sml) {
                flex-direction: row;
                align-items: center;
                gap: calc($unit * 6);

                & .u-icon--arrow-right--secondary {
                    transform: rotate(0deg);
                }
            }

            &--secondary {
                align-items: inherit;
            }
        }
    }

    &__total {
        width: 100%;
        margin-top: calc($unit * 5);

        &__item {
            display: flex;
            align-items: center;
            justify-content: space-between;

            margin-bottom: calc($unit * 2);

            font-size: responsive $type-sml $type-base;
            font-range: 320px 1440px;

            line-height: responsive 20px 24px;
            line-height-range: 320px 1440px;
        }
    }
}
