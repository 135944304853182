/*------------------------------------*\
    # component.membership
\*------------------------------------*/

.c-membership {
    &__wrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        width: 100%;
        height: 100%;

        padding: calc($unit * 6) calc($unit * 7);

        @media (--screen-from-lrg) {
            position: relative;
            align-items: center;
            padding: calc($unit * 6) calc($unit * 10);
        }

        &--info {
            justify-content: center;
            align-items: center;
        }
    }

    &__form {
        display: flex;
        flex-direction: column;

        width: 100%;

        padding-top: calc($unit * 2);
        padding-bottom: calc($unit * 6);

        @media (--screen-from-med) {
            width: 500px;
        }

        @media (--screen-from-lrg) {
            width: 400px;
        }

        &--center {
            height: 100%;
            justify-content: center;
        }

        &__title {
            margin-bottom: calc($unit * 2);

            font-size: responsive $type-med $type-lrg;
            font-range: 320px 1440px;

            line-height: responsive 32px 40px;
            line-height-range: 320px 1440px;

            @media (--screen-from-med) {
                margin-bottom: calc($unit * 6);
            }
        }
    }

    &__cta {
        padding-bottom: calc($unit * 4);

        @media (--screen-from-xxlrg) {
            position: absolute;
            top: calc($unit * 8);
            right: calc($unit * 8);
            left: auto;

            padding-bottom: 0;
        }
    }

    &__hero {
        display: none;

        opacity: 0;
        visibility: hidden;

        transform: translateX(-100%);
        transition: all 250ms $transition;

        @media (--screen-from-lrg) {
            position: sticky;
            top: 0;

            display: block;
            width: 100%;
            min-height: 100vh;
            overflow: hidden;

            background-image: url("../../assets/hero-images/hero-main.png");
            background-size: cover;
            background-position: center center;
            text-align: center;

            opacity: 1;
            visibility: visible;
            transform: translateX(0%);
        }

        & .c-tag {
            position: absolute;
            bottom: calc($unit * 5);
            left: 50%;

            font-weight: $type-weight-bold;

            transform: translateX(-50%);
            opacity: 0.8;

            white-space: nowrap;
        }
    }
}
