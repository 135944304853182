/*------------------------------------*\
    # tools.ricky
\*------------------------------------*/
/**
 * Custom grid based on flexbox.
 * $f-grid-gutter - amount of space between columns
 * $f-grid-columns - number of columns of the grid
 */
$grid-gutter: 36px; 
$grid-columns: 12;
/**
 * The flex-col mixin is used for building flexbox grid columns
 */
@define-mixin grid-span $grid-span: 1, $context: $grid-columns {
    width: calc($(grid-span) / $(context) * 100%);
}
@define-mixin grid-push $push: 1, $context: $grid-columns {
    margin-left: calc($(push) / $(context) * 100%);
}
/**
 * The grid-pull mixin is used for pulling columns using margin-left
 * for the space of a number of columns
 */
@define-mixin grid-pull $pull: 1, $context: $grid-columns {
    margin-right: calc($(pull) / $(context) * 100%);
}
/**
 * Row
 */
.row {
    margin-left: calc(-$grid-gutter / 2);
    margin-right: calc(-$grid-gutter / 2);
    display: flex;
    justify-content: left;
    flex-wrap: wrap; 

    &--align-center {
        align-items: center;
    }

    &--align-end {
        align-items: flex-end;
    }
}
/**
 * Column
 */
.col {
    padding-left: calc($grid-gutter / 2);
    padding-right: calc($grid-gutter / 2);
    transition: all 250ms ease-out;
}