/*------------------------------------*\
    # utils.rotete
\*------------------------------------*/

.u-rotate {
    &--180 {
        transform: rotate(180deg);
        transition: 250ms all ease-out;
    }
}
