/*------------------------------------*\
    # utils.floats
\*------------------------------------*/
.u {

    &-pull {
        float: left;

        &--to {
            &--sml {
                @media (--screen-to-sml) {
                    float: left;
                }	
            }

            &--med {
                @media (--screen-to-med) {
                    float: left;
                }	
            }

            &--lrg {
                @media (--screen-to-lrg) {
                    float: left;
                }	
            }
            
            &--xlrg {
                @media (--screen-to-xlrg) {
                    float: left;
                }	
            }

            &--xxlrg {
                @media (--screen-to-xxlrg) {
                    float: left;
                }	
            }
        }

        &--from {
            &--sml {
                @media (--screen-from-sml) {
                    float: left;
                }
            }
            &--med {
                @media (--screen-from-med) {
                    float: left;
                }	
            }

            &--lrg {
                @media (--screen-from-lrg) {
                    float: left;
                }	
            }
            
            &--xlrg {
                @media (--screen-from-xlrg) {
                    float: left;
                }	
            }

            &--xxlrg {
                @media (--screen-from-xxlrg) {
                    float: left;
                }	
            }
        }
    }

    &-push {
        float: right;

         &--to {
            &--sml {
                @media (--screen-to-sml) {
                    float: right;
                }	
            }

            &--med {
                @media (--screen-to-med) {
                    float: right;
                }	
            }

            &--lrg {
                @media (--screen-to-lrg) {
                    float: right;
                }	
            }
            
            &--xlrg {
                @media (--screen-to-xlrg) {
                    float: right;
                }	
            }

            &--xxlrg {
                @media (--screen-to-xxlrg) {
                    float: right;
                }	
            }
        }

        &--from {
            &--sml {
                @media (--screen-from-sml) {
                    float: right;
                }
            }
            &--med {
                @media (--screen-from-med) {
                    float: right;
                }	
            }

            &--lrg {
                @media (--screen-from-lrg) {
                    float: right;
                }	
            }
            
            &--xlrg {
                @media (--screen-from-xlrg) {
                    float: right;
                }	
            }

            &--xxlrg {
                @media (--screen-from-xxlrg) {
                    float: right;
                }	
            }
        }
    }
}