.c-credit-card {
    position: relative;
    display: flex;
    align-items: flex-end;

    width: 100%;
    max-width: 460px;

    height: 192px;

    margin-bottom: calc($unit * 5);
    padding: calc($unit * 6) calc($unit * 8);

    border: 1px solid $color-border;
    border-radius: calc($unit * 4);

    background-image: svg-load("../postcss-svg/credit-cards/credit-background.svg");

    z-index: 2;

    &:after {
        content: "";
        position: absolute;

        left: 31px;
        top: 90px;

        width: calc($unit * 12);
        height: 55px;
        background-size: 100%;
        background-repeat: no-repeat;

        z-index: 2;
        background-image: svg-load("../postcss-svg/defaults/icon-chip.svg");
    }

    @media (--screen-from-med) {
        height: 260px;
        padding: calc($unit * 10) calc($unit * 8);

        &:after {
            left: 33px;
            width: calc($unit * 17);
        }
    }

    &__logo {
        position: absolute;
        right: calc($unit * 8);
        top: calc($unit * 8);

        width: 66px;
        height: 56px;
    }

    &__info {
        color: $white;

        z-index: 2;
    }
}
