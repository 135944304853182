/*------------------------------------*\
    # component.form
\*------------------------------------*/

.c-form {
    &__wrapper {
        width: 100%;
    }

    &__field {
        display: flex;
        flex-direction: column;

        position: relative;
        margin-bottom: calc($unit * 5);

        &__label {
            display: inline-block;
            width: 100%;

            font-size: $type-sml;
            line-height: 20px;
            margin-bottom: calc($unit * 2);

            &--info {
                display: flex;
                justify-content: space-between;
                width: 100%;
            }
        }

        &__info {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
        }

        &--med {
            width: 100%;

            @media (--screen-from-med) {
                max-width: calc($unit * 95);
            }
        }
    }

    &__footer {
        margin-top: calc($unit * 5);

        &__list {
            display: flex;
            flex-wrap: wrap;

            margin-bottom: calc($unit * 3);

            @media (--screen-from-sml) {
                margin-bottom: $unit;
            }
        }
    }
}
