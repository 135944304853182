/*------------------------------------*\
    # component.validation
\*------------------------------------*/

.c-validation {
    display: flex;
    align-items: center;
    margin-bottom: calc($unit * 1);

    &__label {
        font-size: $type-sml;
        line-height: 20px;
        color: $color-text-light;
    }

    &--success {
        position: relative;
        padding: calc($unit * 2) calc($unit * 4);
        padding-left: calc($unit * 10);

        border-radius: $radius-base;
        border: 1px solid $color-success;
        background-color: color($color-success a(0.1));

        &:before {
            content: svg-load(
                "../postcss-svg/approve.svg",
                stroke: $color-success
            );
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
            left: calc($unit * 2);
            height: calc($unit * 6);
        }
    }

    &--error {
        position: relative;
        padding: calc($unit * 2) calc($unit * 4);
        padding-left: calc($unit * 10);

        border-radius: $radius-base;
        border: 1px solid $color-warning;
        background-color: color($color-warning a(0.1));

        &:before {
            content: svg-load(
                "../postcss-svg/exclamation.svg",
                stroke: $color-warning
            );
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
            left: calc($unit * 2);
            height: calc($unit * 6);
        }

        &--secondary {
            position: relative;
            padding: calc($unit * 2) calc($unit * 4);

            border-radius: $radius-base;
            border: 1px solid $color-warning;
            background-color: color($color-warning a(0.1));
        }
    }

    &--info {
        position: relative;
        display: flex;
        align-items: center;
        padding: calc($unit * 2) calc($unit * 4);
        padding-left: calc($unit * 10);

        border-radius: $radius-base;
        border: 1px solid $color-note;
        background-color: color($color-note a(0.1));

        &:before {
            content: svg-load(
                "../postcss-svg/exclamation.svg",
                stroke: $color-note
            );
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
            left: calc($unit * 2);
            height: calc($unit * 6);
        }

        &--secondary {
            position: relative;
            padding: 0 calc($unit * 3);
            padding-left: calc($unit * 6.5);

            margin-right: calc($unit * 3);

            &:before {
                content: svg-load(
                    "../postcss-svg/exclamation-sml.svg",
                    stroke: $color-note
                );
                height: calc($unit * 5);
            }
        }

        &--tertiary {
            flex-direction: column;
            align-items: flex-start;

            padding-left: calc($unit * 14);

            &:before {
                left: calc($unit * 4);
            }
        }
    }

    &--trial {
        position: relative;

        display: flex;
        flex-direction: column;
        align-items: flex-start;

        padding: calc($unit * 2) calc($unit * 4);
        padding-left: calc($unit * 14);

        border-radius: $radius-base;
        border: 1px solid $color-secondary;
        background-color: color($color-secondary a(0.1));

        &:before {
            content: svg-load(
                "../postcss-svg/approve.svg",
                stroke: $color-secondary
            );
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
            left: calc($unit * 4);
            height: calc($unit * 6);
        }
    }

    &--rules {
        position: relative;

        padding: $unit calc($unit * 3);
        margin-top: $unit;

        border-radius: $radius-base;
        background-color: $lavender;
        color: $color-text-dark;

        font-size: $type-sml;
        line-height: 20px;

        &::after {
            content: "";
            position: absolute;
            border-color: transparent $lavender transparent transparent;
            border-style: solid;
            border-width: 6px;
            right: 8px;
            top: -6px;
        }
    }

    &--input {
        &::before {
            content: "";
            position: absolute;
            left: -14px;
            top: 8px;
            bottom: 0;
            width: 3px;
            background-color: $color-warning;
        }

        &__icon {
            width: 12px;
            fill: $color-warning;
            margin-right: calc($unit * 1);
        }

        &__label {
            font-size: $type-sml;
            color: $color-warning;
        }
    }
}
