/*------------------------------------*\
    # component.search
\*------------------------------------*/

.search {
    &__icon {
        display: inline-block;
        padding: 0 calc($unit * 8);

        background-repeat: no-repeat;
        background-position: center center;
        vertical-align: middle;

        width: calc($unit * 6);
        height: calc($unit * 6);

        /* background-image: svg-load("./postcss-svg/search.svg", stroke: #ffffff); */
        transform: rotate(0deg);
        transform-origin: center;
        transition: all 300ms $transition;

        border: 1px solid transparent;

        &:hover,
        &:focus {
            transform: rotate(90deg);
        }
    }
}
