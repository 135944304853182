/*------------------------------------*\
    # component.c-featured
\*------------------------------------*/

.c-featured {
    &__btn-group {
        position: absolute;
        display: flex;
        gap: calc($unit * 2);

        &--right {
            flex-direction: column;

            right: calc(-$unit * 3);
            top: 50%;
            transform: translateY(-50%);
        }

        &--left {
            flex-direction: column;

            left: calc(-$unit * 3);
            top: 50%;
            transform: translateY(-50%);
        }

        &--bottom {
            left: 50%;
            transform: translateX(-50%);
            bottom: calc(-$unit * 3);
        }

        &--top {
            left: 50%;
            transform: translateX(-50%);
            top: calc(-$unit * 3);
        }
    }

    &__card {
        display: flex;
        flex-direction: column;
        gap: calc($unit * 2);

        height: 100%;

        &__img {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            width: 100%;
            height: 100%;

            border: 1px solid $elevation-00;

            & img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }
    }

    &__img {
        &--sml {
            min-height: 150px;
            max-height: 150px;
        }

        &--med {
            min-height: 440px;
            max-height: 440px;
        }

        &--lrg {
            min-height: 650px;
            max-height: 650px;
        }
    }
}
