/*------------------------------------*\
    # utils.grid
\*------------------------------------*/

/**
 * Small Grid Push Offset
 */
.col-push-sml-1 {
    @mixin grid-push 1;
}

.col-push-sml-2 {
    @mixin grid-push 2;
}

.col-push-sml-3 {
    @mixin grid-push 3;
}

.col-push-sml-4 {
    @mixin grid-push 4;
}

.col-push-sml-5 {
    @mixin grid-push 5;
}

.col-push-sml-6 {
    @mixin grid-push 6;
}

.col-push-sml-7 {
    @mixin grid-push 7;
}

.col-push-sml-8 {
    @mixin grid-push 8;
}

.col-push-sml-9 {
    @mixin grid-push 9;
}

.col-push-sml-10 {
    @mixin grid-push 10;
}

.col-push-sml-11 {
    @mixin grid-push 11;
}

.col-push-sml-12 {
    @mixin grid-push 12;
}

/**
 * Medium Grid Push Offset
 */
@media (--screen-from-sml) {
    .col-push-med-1 {
        @mixin grid-push 1;
    }

    .col-push-med-2 {
        @mixin grid-push 2;
    }

    .col-push-med-3 {
        @mixin grid-push 3;
    }

    .col-push-med-4 {
        @mixin grid-push 4;
    }

    .col-push-med-5 {
        @mixin grid-push 5;
    }

    .col-push-med-6 {
        @mixin grid-push 6;
    }

    .col-push-med-7 {
        @mixin grid-push 7;
    }

    .col-push-med-8 {
        @mixin grid-push 8;
    }

    .col-push-med-9 {
        @mixin grid-push 9;
    }

    .col-push-med-10 {
        @mixin grid-push 10;
    }

    .col-push-med-11 {
        @mixin grid-push 11;
    }

    .col-push-med-12 {
        @mixin grid-push 12;
    }
}

/**
 * Large Grid Push Offset
 */
 @media (--screen-from-med) {
    .col-push-lrg-1 {
        @mixin grid-push 1;
    }

    .col-push-lrg-2 {
        @mixin grid-push 2;
    }

    .col-push-lrg-3 {
        @mixin grid-push 3;
    }

    .col-push-lrg-4 {
        @mixin grid-push 4;
    }

    .col-push-lrg-5 {
        @mixin grid-push 5;
    }

    .col-push-lrg-6 {
        @mixin grid-push 6;
    }

    .col-push-lrg-7 {
        @mixin grid-push 7;
    }

    .col-push-lrg-8 {
        @mixin grid-push 8;
    }

    .col-push-lrg-9 {
        @mixin grid-push 9;
    }

    .col-push-lrg-10 {
        @mixin grid-push 10;
    }

    .col-push-lrg-11 {
        @mixin grid-push 11;
    }

    .col-push-lrg-12 {
        @mixin grid-push 12;
    }
}

/**
 * XLarge Grid Push Offset
 */
@media (--screen-from-lrg) {
    .col-push-xlrg-1 {
        @mixin grid-push 1;
    }

    .col-push-xlrg-2 {
        @mixin grid-push 2;
    }

    .col-push-xlrg-3 {
        @mixin grid-push 3;
    }

    .col-push-xlrg-4 {
        @mixin grid-push 4;
    }

    .col-push-xlrg-5 {
        @mixin grid-push 5;
    }

    .col-push-xlrg-6 {
        @mixin grid-push 6;
    }

    .col-push-xlrg-7 {
        @mixin grid-push 7;
    }

    .col-push-xlrg-8 {
        @mixin grid-push 8;
    }

    .col-push-xlrg-9 {
        @mixin grid-push 9;
    }

    .col-push-xlrg-10 {
        @mixin grid-push 10;
    }

    .col-push-xlrg-11 {
        @mixin grid-push 11;
    }

    .col-push-xlrg-12 {
        @mixin grid-push 12;
    }
}

/**
 * XXLarge Grid Push Offset
 */
@media (--screen-from-xlrg) {
    .col-push-xxlrg-1 {
        @mixin grid-push 1;
    }

    .col-push-xxlrg-2 {
        @mixin grid-push 2;
    }

    .col-push-xxlrg-3 {
        @mixin grid-push 3;
    }

    .col-push-xxlrg-4 {
        @mixin grid-push 4;
    }

    .col-push-xxlrg-5 {
        @mixin grid-push 5;
    }

    .col-push-xxlrg-6 {
        @mixin grid-push 6;
    }

    .col-push-xxlrg-7 {
        @mixin grid-push 7;
    }

    .col-push-xxlrg-8 {
        @mixin grid-push 8;
    }

    .col-push-xxlrg-9 {
        @mixin grid-push 9;
    }

    .col-push-xxlrg-10 {
        @mixin grid-push 10;
    }

    .col-push-xxlrg-11 {
        @mixin grid-push 11;
    }

    .col-push-xxlrg-12 {
        @mixin grid-push 12;
    }
}

/**
 * Small Grid Push Offset
 */
.col-pull-sml-1 {
    @mixin grid-pull 1;
}

.col-pull-sml-2 {
    @mixin grid-pull 2;
}

.col-pull-sml-3 {
    @mixin grid-pull 3;
}

.col-pull-sml-4 {
    @mixin grid-pull 4;
}

.col-pull-sml-5 {
    @mixin grid-pull 5;
}

.col-pull-sml-6 {
    @mixin grid-pull 6;
}

.col-pull-sml-7 {
    @mixin grid-pull 7;
}

.col-pull-sml-8 {
    @mixin grid-pull 8;
}

.col-pull-sml-9 {
    @mixin grid-pull 9;
}

.col-pull-sml-10 {
    @mixin grid-pull 10;
}

.col-pull-sml-11 {
    @mixin grid-pull 11;
}

.col-pull-sml-12 {
    @mixin grid-pull 12;
}

/**
 * Medium Grid Pull Offset
 */
@media (--screen-from-sml) {
    .col-pull-med-1 {
        @mixin grid-pull 1;
    }

    .col-pull-med-2 {
        @mixin grid-pull 2;
    }

    .col-pull-med-3 {
        @mixin grid-pull 3;
    }

    .col-pull-med-4 {
        @mixin grid-pull 4;
    }

    .col-pull-med-5 {
        @mixin grid-pull 5;
    }

    .col-pull-med-6 {
        @mixin grid-pull 6;
    }

    .col-pull-med-7 {
        @mixin grid-pull 7;
    }

    .col-pull-med-8 {
        @mixin grid-pull 8;
    }

    .col-pull-med-9 {
        @mixin grid-pull 9;
    }

    .col-pull-med-10 {
        @mixin grid-pull 10;
    }

    .col-pull-med-11 {
        @mixin grid-pull 11;
    }

    .col-pull-med-12 {
        @mixin grid-pull 12;
    }
}

/**
 * Large Grid Pull Offset
 */
 @media (--screen-from-med) {
    .col-pull-lrg-1 {
        @mixin grid-pull 1;
    }

    .col-pull-lrg-2 {
        @mixin grid-pull 2;
    }

    .col-pull-lrg-3 {
        @mixin grid-pull 3;
    }

    .col-pull-lrg-4 {
        @mixin grid-pull 4;
    }

    .col-pull-lrg-5 {
        @mixin grid-pull 5;
    }

    .col-pull-lrg-6 {
        @mixin grid-pull 6;
    }

    .col-pull-lrg-7 {
        @mixin grid-pull 7;
    }

    .col-pull-lrg-8 {
        @mixin grid-pull 8;
    }

    .col-pull-lrg-9 {
        @mixin grid-pull 9;
    }

    .col-pull-lrg-10 {
        @mixin grid-pull 10;
    }

    .col-pull-lrg-11 {
        @mixin grid-pull 11;
    }

    .col-pull-lrg-12 {
        @mixin grid-pull 12;
    }
}

/**
 * XLarge Grid Pull Offset
 */
@media (--screen-from-lrg) {
    .col-pull-xlrg-1 {
        @mixin grid-pull 1;
    }

    .col-pull-xlrg-2 {
        @mixin grid-pull 2;
    }

    .col-pull-xlrg-3 {
        @mixin grid-pull 3;
    }

    .col-pull-xlrg-4 {
        @mixin grid-pull 4;
    }

    .col-pull-xlrg-5 {
        @mixin grid-pull 5;
    }

    .col-pull-xlrg-6 {
        @mixin grid-pull 6;
    }

    .col-pull-xlrg-7 {
        @mixin grid-pull 7;
    }

    .col-pull-xlrg-8 {
        @mixin grid-pull 8;
    }

    .col-pull-xlrg-9 {
        @mixin grid-pull 9;
    }

    .col-pull-xlrg-10 {
        @mixin grid-pull 10;
    }

    .col-pull-xlrg-11 {
        @mixin grid-pull 11;
    }

    .col-pull-xlrg-12 {
        @mixin grid-pull 12;
    }
}

/**
 * XXLarge Grid Pull Offset
 */
@media (--screen-from-xlrg) {
    .col-pull-xxlrg-1 {
        @mixin grid-pull 1;
    }

    .col-pull-xxlrg-2 {
        @mixin grid-pull 2;
    }

    .col-pull-xxlrg-3 {
        @mixin grid-pull 3;
    }

    .col-pull-xxlrg-4 {
        @mixin grid-pull 4;
    }

    .col-pull-xxlrg-5 {
        @mixin grid-pull 5;
    }

    .col-pull-xxlrg-6 {
        @mixin grid-pull 6;
    }

    .col-pull-xxlrg-7 {
        @mixin grid-pull 7;
    }

    .col-pull-xxlrg-8 {
        @mixin grid-pull 8;
    }

    .col-pull-xxlrg-9 {
        @mixin grid-pull 9;
    }

    .col-pull-xxlrg-10 {
        @mixin grid-pull 10;
    }

    .col-pull-xxlrg-11 {
        @mixin grid-pull 11;
    }

    .col-pull-xxlrg-12 {
        @mixin grid-pull 12;
    }
}