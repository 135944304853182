/*------------------------------------*\
    # component.ReactModal
\*------------------------------------*/

.modal {
    &__wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__head {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;

        margin-bottom: calc($unit * 4);

        font-size: $type-lrg;
        line-height: 1;

        &--end {
            justify-content: flex-end;
        }
    }

    &__icon--close {
        float: right !important;
        cursor: pointer;
        transition: all 250ms $transition;

        &:hover {
            opacity: 0.7;
        }
    }

    &__overlay {
        z-index: 5;
        position: fixed;
        top: 0;
        left: 0;
        right: 0px;
        bottom: 0px;
        background-color: color($grey-9 a(0.6));

        @media (--screen-from-lrg) {
            left: 200px;
        }
    }

    &__content {
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: calc($unit * 6);
        border-radius: $radius-base;
        background-color: $color-background-primary;

        &--med {
            @media (--screen-from-lrg) {
                width: 440px !important;
            }
        }

        &--lrg {
            @media (--screen-from-lrg) {
                width: 540px !important;
            }
        }

        &--secondary {
            background-color: $elevation-04;

            &.ReactModal__Content {
                max-width: calc($unit * 150);
                width: 90%;

                & .modal__body {
                    max-width: 100%;
                }
            }
        }

        &:focus {
            outline: 0;
        }
    }

    &__icon {
        display: flex;
        justify-content: center;
    }

    &__header {
        padding: calc($unit * 2) 0;
        text-align: center;

        font-size: responsive $type-sml $type-base;
        font-range: 320px 1440px;

        line-height: responsive 20px 24px;
        line-height-range: 320px 1440px;

        &--secondary {
            text-align: left;
        }
    }

    &__body {
        padding-bottom: calc($unit * 4);
        min-width: 250px;
        max-width: 350px;

        text-align: center;

        &__list {
            padding-left: calc($unit * 4);
            list-style: decimal;
        }

        &__list-item {
            text-align: left;
            font-size: responsive $type-sml $type-base;
            font-range: 320px 1440px;

            line-height: responsive 20px 24px;
            line-height-range: 320px 1440px;
        }
    }

    &__footer {
        text-align: center;

        &--secondary {
            display: flex;
            justify-content: space-between;

            padding: calc($unit * 3);
        }

        &--column {
            display: flex;
            flex-direction: column;
            align-items: center;

            gap: calc($unit * 2);
        }

        &__btn-group {
            display: flex;
            align-items: center;

            gap: calc($unit * 2);
        }
    }

    &__splitter {
        position: relative;
        width: 100%;

        &:before,
        &:after {
            position: absolute;
            content: "";
            height: 1px;
            width: 43%;
            top: 50%;

            transform: translateY(-50%);

            background-color: color($white a(0.3));
        }

        &:before {
            left: 0;
        }

        &:after {
            right: 0;
        }
    }
}

.ReactModal__Content {
    width: 328px;

    @media (--screen-from-lrg) {
        width: 368px;
    }
}
