/*------------------------------------*\
    # component.header
\*------------------------------------*/

.c-header {
    &__actions {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;

        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: $unit;

        @media (--screen-from-med) {
            justify-content: flex-end;
        }

        height: 70px;
        min-width: 300px;

        padding: calc($unit * 4);

        border-top: 1px solid $elevation-08;
        background-color: $color-background-secondary;

        z-index: 15;

        /* & .c-btn:last-child {
            margin-left: calc($unit * 2);
        } */

        &--end {
            justify-content: flex-end;

            @media (--screen-from-sml) {
                margin-left: calc($unit * 3.5);
            }
        }

        &--sml {
            min-width: 120px;
        }

        &__premium-info {
            display: flex;
        }

        & .c-validation--info,
        & .c-validation--error {
            position: fixed;
            top: 17px;
            right: 64px;

            @media (--screen-from-sml) {
                right: 0;
                margin-right: 12px;
            }
        }

        @media (--screen-from-sml) {
            position: relative;
            padding: 0;

            border-top: 0;
            background-color: transparent;

            & .c-validation--info,
            & .c-validation--error {
                position: relative;
                top: 0;
            }
        }

        &__dropdown {
            position: absolute;
            bottom: 120%;
            width: 92vw;

            display: flex;
            flex-direction: column;
            gap: calc($unit * 4);

            padding: calc($unit * 4);

            border-radius: $radius-base;
            background-color: $elevation-02;
            border: 1px solid $elevation-08;

            & .c-btn--primary {
                width: 100%;
            }
        }
    }
}
