/*------------------------------------*\
    # component.rich-text-editor
\*------------------------------------*/

.rdw {
    &-editor {
        &-toolbar {
            padding: 0;

            background: transparent;
            border: 0;
        }

        &-main {
            min-height: 98px;
            padding: calc($unit * 2) calc($unit * 3);

            color: $color-text-light;
            font-family: $ff-sans;
            font-size: responsive $type-sml $type-base;
            font-range: 320px 1440px;

            line-height: responsive 20px 24px;
            line-height-range: 320px 1440px;

            border: 1px solid $color-text-light;
            border-radius: $radius-base;
        }
    }

    &-option {
        &-wrapper {
            padding: calc($unit / 2);
            background: $white;
            border: 1px solid $white;
            transition: 0.3s all;

            &:hover {
                background-color: $grey-2;
                border-color: $grey-2;
            }

            &:first-child {
                margin-left: 0;
            }
        }

        &-active {
            background-color: $grey-3;
            border-color: $grey-3;
        }

        &-disabled {
            background-color: $grey-4;

            &:hover {
                background-color: $grey-4;
            }
        }
    }

    &-dropdown {
        &-wrapper {
            height: calc($unit * 6);

            color: $color-text-dark;

            font-family: $ff-sans;
            font-size: responsive $type-sml $type-base;
            font-range: 320px 1440px;

            line-height: responsive 20px 24px;
            line-height-range: 320px 1440px;
        }

        &-selectedtext {
            color: $color-text-dark;

            &:hover {
                color: color($color-text-dark a(0.6));
            }
        }
    }

    &-link {
        &-modal {
            left: -153px;
            height: auto;

            padding: calc($unit * 3);

            background-color: $elevation-08;
            border-color: $elevation-04;
            border-radius: $radius-base;
            box-shadow: none;

            z-index: 2 !important;

            @media (--screen-from-sml) {
                top: 0;
                left: 78px;
            }

            &-label,
            &-target-option {
                font-size: $type-sml;
                line-height: 20px;
            }

            &-label {
                margin-bottom: $unit;
            }

            &-input {
                margin: 0;
                margin-bottom: calc($unit * 2);
                padding: calc($unit * 1) calc($unit * 3);

                font-size: $type-base;
                color: $color-text-light;
                background-color: transparent;
                border-radius: $radius-base;

                &:focus {
                    outline: 0;
                    border-radius: 6px;
                    box-shadow: 0 0 0 2px $color-secondary;
                }
            }

            &-target-option {
                display: flex;
                align-items: center;

                margin-bottom: calc($unit * 2);
            }

            &-buttonsection {
                margin: 0;
                margin-left: auto;
            }

            &-btn {
                width: auto;
                height: auto;

                margin-left: 0;
                padding: calc($unit * 2) calc($unit * 5);

                font-size: $type-base;

                border-radius: $radius-base;
                border: 1px solid transparent;
                background: $color-primary;
                color: $color-text-dark;

                &:hover {
                    background: color($color-primary blackness(+10%));
                    box-shadow: none;
                }

                &[disabled] {
                    background: $color-primary;
                    pointer-events: none;
                    opacity: 0.5;
                    border-color: transparent;
                }

                &:last-child {
                    margin-left: calc($unit * 2);

                    background-color: transparent;
                    color: $color-primary;
                    border: 1px solid $color-primary;

                    &:hover {
                        background-color: transparent;
                        border: 1px solid color($color-primary a(0.7));
                    }
                }

                &:focus {
                    outline: 0;
                    border-radius: $radius-base;
                    box-shadow: 0px 0px 0px 2px $color-secondary inset;
                }
            }
        }
    }
}

.public-DraftEditorPlaceholder-root {
    color: color($color-text-light a(0.6));
    opacity: 0.54;
}

.public-DraftStyleDefault-block {
    margin: 0;
}
