/*------------------------------------*\
    # utils.anchor
\*------------------------------------*/

.u-anchor {
	&--negative {
		color: #ffffff;
		text-decoration: underline;

		transition: all 250ms ease-out;

		&:hover {
			color: color(#ffffff a(0.6));
		}
		&:focus {
			outline: 0;
			border-radius: 2px;
			box-shadow: 0 0 0 2px $color-secondary;
			color: color($color-secondary lightness(+10));
		}
		&:visited {
			color: color($color-secondary lightness(+20));
		}
	}
}