.c-tag {
    display: inline-flex;
    justify-content: center;
    align-items: center;

    gap: $unit;

    border-radius: $radius-base;

    &--sml {
        min-width: calc($unit * 17);
        padding: calc($unit / 1.5) calc($unit * 3);

        font-size: $type-sml;
        line-height: 16px;
    }

    &--rounded {
        border-radius: $radius-full;
    }

    &--disabled {
        border: 1px solid $elevation-10;
        background-color: $elevation-08;
    }

    &--primary {
        background-color: $color-primary;
        color: $white;
    }

    &--active {
        background-color: $color-success;
        color: $color-text-dark;

        &--outline {
            border: 1px solid $color-success;
            color: $color-success;
        }
    }

    &--inactive {
        background-color: $color-text-light;
        color: $color-text-dark;

        &.with-actions {
            &:hover {
                transition: all 250ms ease-out;
                background-color: color($color-text-light a(0.9));
            }
        }

        &--outline {
            border: 1px solid $color-text-light;
            color: $color-text-light;
        }
    }
}
