/*------------------------------------*\
    # plugin.date-picker
\*------------------------------------*/

.react-datepicker {
    background-color: $elevation-06;
    border: 1px solid $color-border;

    font-family: $ff-sans;

    &-wrapper {
        & .react-datepicker__input-container {
            max-width: calc($unit * 30);

            & .c-input {
                background-color: $color-background-primary;
                color: $color-text-light;

                &::placeholder {
                    text-align: center;
                    color: $color-text-light !important;
                }
            }
        }
    }

    &--has-btn {
        & .react-datepicker__month {
            padding-bottom: calc($unit * 3);

            border-bottom: 1px solid $grey-5;
        }
    }

    & .react-datepicker__month-container {
        & .react-datepicker__header {
            border-bottom: 1px solid $color-border;
            background-color: $elevation-02;
            color: $color-text-light;

            & .react-datepicker__current-month {
                color: $color-text-light;
            }

            & .react-datepicker__day-names {
                & .react-datepicker__day-name {
                    color: $color-text-light;
                    font-weight: $type-weight-bold;
                }
            }
        }
    }

    &__month {
        &-text {
            color: $color-text-light;
            border-radius: $radius-base;

            font-size: responsive $type-sml $type-base;
            font-range: 320px 1440px;

            line-height: responsive 20px 24px;
            line-height-range: 320px 1440px;

            transition: background-color 150ms ease-in-out;

            &:hover {
                background-color: color($color-primary a(0.8));
            }

            &--keyboard-selected {
                background-color: transparent;

                &:hover {
                    background-color: color($color-primary a(0.8)) !important;
                }
            }
        }

        &--in-range {
            background-color: color($color-primary a(0.5));

            &:hover {
                background-color: color($color-primary a(0.8)) !important;
            }
        }

        &--range {
            &-start {
                background-color: $color-primary !important;

                &:hover {
                    background-color: color($color-primary a(1)) !important;
                }
            }

            &-end {
                background-color: $color-primary !important;

                &:hover {
                    background-color: color($color-primary a(1)) !important;
                }
            }
        }

        &--selected {
            background-color: color($color-primary a(0.9)) !important;

            &:hover {
                background-color: color($color-primary a(0.7)) !important;
            }
        }
    }

    &__day {
        color: $color-text-light;
        transition: all 300ms $transition;
        border-radius: 0.3rem !important;
        &:hover {
            background-color: $elevation-08;
        }

        &--disabled {
            color: color($color-text-light a(0.5));

            &:hover {
                background-color: transparent;
            }
        }

        &--selected {
            background-color: color($color-primary a(0.9)) !important;

            &:hover {
                background-color: color($color-primary a(0.7)) !important;
            }
        }

        &--keyboard-selected {
            background-color: $color-secondary;

            &:hover {
                background-color: $color-secondary;
            }
        }

        @media (--screen-from-lrg) {
            width: 1.3rem;
            line-height: 1.3rem;
            font-size: $type-sml;
        }
    }

    @media (--screen-from-lrg) {
        &__navigation {
            top: 4%;
        }

        & .react-datepicker__month-container {
            & .react-datepicker__header {
                color: $color-text-light;

                font-size: responsive $type-base $type-med;
                font-range: 320px 1440px;

                line-height: responsive 28px 32px;
                line-height-range: 320px 1440px;

                & .react-datepicker__current-month {
                    font-size: $type-med;
                }

                & .react-datepicker__day-names {
                    & .react-datepicker__day-name {
                        width: 1.3rem;
                        line-height: 1.3rem;
                        font-size: $type-sml;
                    }
                }
            }

            & .react-datepicker__month {
                margin: 0.1rem 0.4rem;

                &--disabled {
                    color: color($color-text-light a(0.6));
                }

                &--selected {
                    color: $color-text-light;
                }
            }
        }
    }

    /* ANALYTICS DATEPICKER */
    &__day {
        background-color: transparent;

        &--in-range {
            background-color: color($color-primary a(0.9));
            color: $color-text-light;
        }

        &--disabled {
            background-color: color($color-primary a(0.15));
            border-radius: $radius-base;

            &:hover {
                background-color: color($color-primary a(0.2)) !important;
            }
        }

        &:hover {
            background-color: color($color-primary a(0.5));
        }
    }

    &__header {
        margin-bottom: calc($unit * 2);
    }

    &__month {
        &--selecting-range {
            &.react-datepicker__day {
                background-color: color($color-primary a(0.2));
            }

            & .react-datepicker__day--in-range {
                color: $color-text-light;
                background-color: color($color-primary a(0.9));

                &:hover {
                    background-color: color($color-primary a(0.7));
                }
            }

            & .react-datepicker__day--disabled.react-datepicker__day--in-range {
                color: hsla(0, 0%, 96.1%, 0.5);
                background-color: color($color-primary a(0.2));
            }
        }

        &-container {
            padding-bottom: calc($unit * 3);
        }
    }

    &__today-button {
        margin: calc($unit * 3);
        margin-bottom: calc($unit * 2);

        border-radius: $radius-base;

        background-color: $color-primary;

        font-weight: $type-weight-regular;
        font-size: responsive $type-sml $type-base;
        font-range: 320px 1440px;

        line-height: responsive 20px 24px;
        line-height-range: 320px 1440px;

        transition: all 250ms ease-in-out;

        &:hover {
            background-color: color($color-primary a(0.8));
        }
    }
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle:before {
    border-bottom-color: $color-border;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle:after {
    border-bottom-color: $elevation-02;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle:before {
    border-top-color: $color-border;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle:after {
    border-top-color: $color-border;
}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range) {
    background-color: transparent;
    &:hover {
        background-color: color($color-primary a(0.5));
    }
}
