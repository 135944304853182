/*------------------------------------*\
    # components.c-bar-loader
\*------------------------------------*/

.c-bar-loader {
	position: fixed;
	top: -8px;
	left: 0;
	height: 4px;
	display: block;
	width: 100%;
	background-color: color($color-primary a(30%));
	border-radius: 2px;
	background-clip: padding-box;
	margin: 8px 0 16px 0;
	overflow: hidden;
	z-index: 999;

	&__progress {
		background-color: $color-primary;

		&:before {
			content: '';
			position: absolute;
			background-color: inherit;
			top: 0;
			left: 0;
			bottom: 0;
			will-change: left, right;
			animation: loader 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
		}
		&:after {
			content: '';
			position: absolute;
			background-color: inherit;
			top: 0;
			left: 0;
			bottom: 0;
			will-change: left, right;
			animation: loader-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
			animation-delay: 1.15s;
		}
    }
}

/* Loader animation */
@-webkit-keyframes loader {
	0% {
		left: -35%;
		right: 100%;
	}
	60% {
		left: 100%;
		right: -90%;
	}
	100% {
		left: 100%;
		right: -90%;
	}
}
@keyframes loader {
	0% {
		left: -35%;
		right: 100%;
	}
	60% {
		left: 100%;
		right: -90%;
	}
	100% {
		left: 100%;
		right: -90%;
	}
}