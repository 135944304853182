/*------------------------------------*\
    # component.c-device
\*------------------------------------*/

.c-device {
    &__card {
        position: relative;
        border-radius: $radius-base;

        cursor: pointer;

        &.isDisabled {
            opacity: 0.6;
            cursor: not-allowed;
        }

        &--sml {
            display: flex;
            align-items: center;

            width: 100%;
            height: calc($unit * 20);

            margin-right: 0;
            margin-bottom: calc($unit * 6);

            background-color: $color-background-primary;

            @media (--screen-from-med) {
                margin-right: calc($unit * 8);
                width: calc($unit * 70);
            }
        }

        &__icon {
            display: flex;
            justify-content: center;
            align-items: center;

            width: calc($unit * 16);
            height: 100%;

            background-color: $color-primary;
            border-top-left-radius: $radius-base;
            border-bottom-left-radius: $radius-base;

            & .u-icon {
                width: calc($unit * 8);
                height: 100%;
            }
        }

        &__body {
            width: calc($unit * 43);
            padding: 0 calc($unit * 3);

            font-size: responsive $type-sml $type-base;
            font-range: 320px 1440px;

            line-height: responsive 20px 24px;
            line-height-range: 320px 1440px;

            word-break: break-all;
        }

        &__delete {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            width: 44px;

            display: flex;
            align-items: center;
            justify-content: center;

            background-color: $charm;

            border-top-right-radius: $radius-base;
            border-bottom-right-radius: $radius-base;

            transition: 0.3s all;

            &:hover {
                background-color: color($charm a(0.9));
            }
        }
    }

    &__device {
        display: flex;
        flex-direction: column;
        align-items: center;

        &__display {
            max-width: 334px;
            width: 100%;
            height: calc($unit * 75);

            margin-bottom: calc($unit * 4);

            & img {
                width: 100%;
                height: 100%;

                border-radius: $radius-base;
            }
        }
    }

    &__downtime {
        display: grid;
        grid-template-columns: 100px 1fr;
        height: 280px;

        &__list {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;

            width: 100%;
            height: calc($unit * 8);
            min-width: 1010px;

            @media (--screen-from-xlrg) {
                padding-right: 0;
            }
        }

        &__item {
            width: calc($unit * 7);
            height: calc($unit * 7);

            font-size: $type-sml;
            line-height: 20px;
        }

        &__days {
            width: calc($unit * 25);

            display: flex;
            flex-direction: column;
            justify-content: space-evenly;

            padding-top: calc($unit * 7);
            padding-bottom: calc($unit * 4);

            color: color($color-text-light a(0.8));
            font-size: $type-sml;
            line-height: 20px;
        }

        &__hours {
            padding-right: calc($unit * 5);
            padding-bottom: calc($unit * 5);

            overflow: hidden;
            overflow-x: auto;

            scrollbar-width: thin;

            z-index: 0;

            & .c-input--check + label {
                display: initial;
            }

            & .c-input--check {
                display: none;
            }
        }
    }

    &__navigation {
        width: calc($unit * 1.5);
        height: calc($unit * 1.5);

        margin-right: calc($unit * 2);

        border-radius: $radius-full;
        background-color: $color-border;

        &--active {
            background-color: $color-primary;
        }
    }

    &__info-icon {
        width: 150px;
        height: 150px;

        @media (--screen-from-med) {
            width: 200px;
            height: 200px;
        }
    }

    &__list {
        display: flex;
        gap: calc($unit * 4);

        margin-top: calc($unit * 6);
        margin-bottom: calc($unit * 2);
        padding: calc($unit * 2) 0;

        overflow-x: auto;

        & img {
            max-width: calc($unit * 30);
            max-height: calc($unit * 30);

            border: 1px solid color($color-text-light a(0.35));
            border-radius: $radius-base;
        }

        &__item {
            display: flex;
            flex-direction: column;

            position: relative;

            &--station {
                & img {
                    border-radius: $radius-full;
                }
            }
        }
    }

    &__item {
        &__title {
            width: 100%;
            max-width: calc($unit * 30);
            margin-top: $unit;

            text-align: left;
            line-height: 1.2;

            color: color($white a(0.9));
            border-bottom: 1px solid color($color-primary a(0));

            transition: 250ms all;

            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    }

    &__btn {
        position: absolute;
        top: -$unit;
        right: -$unit;

        min-width: auto;

        padding: $unit;

        background-color: $color-primary;
        border-radius: $radius-full;

        &:hover {
            background-color: $color-primary;
        }
    }

    &__header {
        display: flex;
        flex-wrap: wrap;
        gap: calc($unit * 4);
    }
}
