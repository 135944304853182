/*------------------------------------*\
    # component.layout
\*------------------------------------*/

$aside-width: 200px;
$header-width: 77px;

.l {
    &__public {
        display: grid;
        grid-template-columns: 1fr;
        min-height: 100vh;

        background: $body-background;

        @media (--screen-from-lrg) {
            align-items: flex-start;
            justify-content: center;
            grid-template-columns: 500px 1fr;
        }

        @media (--screen-from-xlrg) {
            grid-template-columns: 700px 1fr;
        }
    }

    &__admin {
        background: $body-background;

        &.is-open {
            & .l__admin--aside {
                width: 100vw;

                visibility: visible;
                z-index: 24;

                transition: 0.3s all;
                transform: translateX(0%);

                @media (--screen-from-sml) {
                    z-index: 24;
                    width: $aside-width;
                }

                & .c-nav__wrapper {
                    display: flex;
                    flex-direction: column;
                    padding: calc($unit * 4) 0;
                }
            }

            & .c-nav__btn {
                /* left: auto;
                left: 90vw; */

                background-color: color($elevation-08 a(0.9));

                border: 1px solid $elevation-04;

                @media (--screen-from-sml) {
                    /* left: 184px; */
                }

                &::before {
                    top: 19px;
                    width: calc($unit * 3);
                    transform: rotate(45deg);

                    background-color: $color-text-light;
                }

                &::after {
                    top: 11px;

                    width: calc($unit * 3);
                    background-color: $color-text-light;
                    transform: rotate(-45deg);
                }
            }
        }

        &--aside {
            position: fixed;
            top: 0;

            display: grid;
            grid-template-rows: $header-width 1fr;

            width: $aside-width;
            height: 100%;
            min-height: 100vh;

            background-color: $elevation-04;
            border-right: 1px solid $color-text-dark;
            visibility: hidden;
            z-index: 25;

            transform: translateX(-100%);
            transition: 0.3s all;

            overflow: hidden;
            overflow-y: auto;

            @media (--screen-from-lrg) {
                transform: translateX(0%);
                visibility: visible;
            }
        }

        &--main {
            display: block;

            height: 100%;
            min-height: 100vh;

            overflow: hidden;
        }
    }
}
