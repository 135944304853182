/*------------------------------------*\
    # component.segmenter
\*------------------------------------*/

.c-segmented {
    opacity: 0;
    position: absolute;

    &:focus-visible {
        & + label {
            outline: 0;
            border-radius: $radius-base;
            box-shadow: 0px 0px 0px 2px $color-secondary;
        }
    }

    &--full {
        & .c-segmented + label {
            justify-content: center;
            width: 100%;
            white-space: nowrap;
        }
    }

    &.c-segmented--first {
        & + label {
            border-top-left-radius: $radius-base;
            border-bottom-left-radius: $radius-base;
        }
    }

    &.c-segmented--last {
        & + label {
            border-top-right-radius: $radius-base;
            border-bottom-right-radius: $radius-base;
        }
    }

    &.active {
        & + label {
            background: $color-primary;
            color: $color-text-light;
            border: 1px solid transparent;

            &:hover {
                background: color($color-primary blackness(+10%));
            }

            &:focus {
                outline: 0;
                border-radius: $radius-base;
                box-shadow: 0px 0px 0px 2px $color-secondary inset;
            }
        }
    }

    & + label {
        display: flex;
        align-items: center;

        padding: calc($unit * 1) calc($unit * 3);

        height: calc($unit * 6.5);

        font-size: $type-sml;
        color: color($color-text-light a(0.9));

        background-color: transparent;
        border: 1px solid $color-primary;

        cursor: pointer;
        transition: all 300ms $transition;

        @media (--screen-from-sml) {
            padding: calc($unit * 1) calc($unit * 3);
            height: calc($unit * 8.5);

            font-size: $type-base;
        }

        &:hover {
            background-color: transparent;
            border: 1px solid color($color-primary a(0.7));
        }

        &:focus {
            background-color: transparent;
            border: 1px solid $color-secondary;
            box-shadow: 0px 0px 0px 2px $color-secondary inset;
        }
    }

    &--sml {
        & + label {
            padding: 0 calc($unit * 3);
            /* padding: calc($unit * 1) calc($unit * 3); */
            margin-top: calc($unit * 1);

            @media (--screen-from-sml) {
                margin-top: 0;
                font-size: $type-base;
            }
        }
    }

    &--base {
        & + label {
            height: calc($unit * 11);

            padding: 0 calc($unit * 6);
            margin-top: calc($unit * 1);

            @media (--screen-from-sml) {
                margin-top: 0;
                font-size: $type-base;
            }
        }
    }

    &[disabled] {
        & + label {
            color: $color-border;
            border: 1px solid $color-border;
        }

        &.active {
            & + label {
                color: $color-text-light;
                background: $color-border;
            }
        }
    }

    &--secondary {
        opacity: 0;
        position: absolute;

        &.c-segmented--first {
            & + label {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }

        &.c-segmented--last {
            & + label {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }

        &:focus-visible {
            & + label {
                outline: 0;
                border-radius: 0;
                box-shadow: 0px 0px 0px 2px $color-secondary;
            }
        }

        &.active {
            & + label {
                background: transparent;
                color: $color-primary;
                border: 0px solid transparent;

                &:before {
                    background-color: $color-primary;
                }

                &:hover {
                    background: transparent;
                    color: $color-primary;
                    border: 0px solid transparent;
                }

                &:focus {
                    outline: 0;
                    border-radius: $radius-base;
                    box-shadow: 0px 0px 0px 2px $color-secondary inset;
                }
            }
        }

        & + label {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            width: calc(100% / 4);

            padding: calc($unit * 0) calc($unit * 6);

            font-size: $type-sml;
            font-weight: $type-weight-bold;
            color: color($color-text-light a(0.6));

            background-color: transparent;
            border: 0px solid transparent;

            cursor: pointer;
            overflow: hidden;
            transition: all 300ms $transition;

            @media (--screen-from-sml) {
                width: auto;
                padding: calc($unit * 0) calc($unit * 4);

                font-size: $type-base;
            }

            &:before {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;

                border: 1px solid transparent;
                color: color($color-text-light a(0.5));

                background-color: color($color-text-light a(0.6));
                transition: 0.3s all;
            }

            &:hover {
                background-color: transparent;
                border: 0px solid transparent;
                color: $color-primary;

                &:before {
                    left: 0;
                    background-color: $color-primary;
                }
            }

            &:focus {
                background-color: transparent;
                border: 1px solid $color-secondary;
                box-shadow: 0px 0px 0px 2px $color-secondary inset;
            }
        }

        &--sml {
            & + label {
                padding: 0 calc($unit * 3);
                margin-top: calc($unit * 1);

                @media (--screen-from-sml) {
                    margin-top: 0;
                    font-size: $type-base;
                }
            }
        }

        &--lrg {
            & + label {
                padding: 0 calc($unit * 8);
                white-space: nowrap;
                overflow: visible;
            }
        }

        &[disabled] {
            & + label {
                color: $color-border;
                border: 1px solid $color-border;
            }

            &.active {
                & + label {
                    color: $color-text-light;
                    background: $color-border;
                }
            }
        }
    }

    &--vertical {
        opacity: 0;
        position: absolute;

        &:focus-visible + label {
            outline: 0;
            border-radius: 0;
        }

        &.active + label {
            background: transparent;
            color: $color-primary;

            border: 1px solid transparent;
            border-right: 1px solid $elevation-08;

            &:hover {
                background: transparent;
                color: $color-primary;
                opacity: 1;

                border: 1px solid transparent;
                border-right: 1px solid $elevation-08;

                &:last-child {
                    border-right: 0;
                }
            }
        }

        & + label {
            position: relative;

            display: flex;
            align-items: center;
            justify-content: center;

            padding: 0 calc($unit * 4);

            font-size: $type-sml;
            font-weight: $type-weight-bold;

            color: color($color-text-light a(0.6));
            background-color: transparent;

            border: 1px solid transparent;
            border-right: 1px solid $elevation-08;

            cursor: pointer;
            overflow: hidden;
            transition: all 300ms $transition;

            &:last-child {
                border-right: 0;
            }

            @media (--screen-from-sml) {
                font-size: $type-base;
            }

            &:hover {
                opacity: 0.7;

                border: 1px solid transparent;
                border-right: 1px solid $elevation-08;

                &:last-child {
                    border-right: 0;
                }
            }

            &:focus {
                background-color: transparent;
                box-shadow: 0 0 0 2px $color-secondary inset;
            }
        }

        &[disabled] + label {
            color: $color-border;
            border: 1px solid $color-border;

            &.active {
                color: $color-text-light;
                background: $color-border;
            }
        }
    }

    &--2 {
        & + label {
            @media (--screen-to-sml) {
                width: calc(100% / 2);
            }
        }
    }
}
