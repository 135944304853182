/*------------------------------------*\
    # view.404
\*------------------------------------*/

.v-not-found {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: $body-background;
    height: 100vh;
}